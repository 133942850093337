import React from 'react';

class Odrzavanje extends React.Component {

    state = {}

    render() {
        return (
            <div id="odrzavanjeIServisiranjeContainer">
                <div className="vrstaOdrzavanja">
                    Klasično (Time & Material) održavanje
                </div>
                <div className="opisVrsteOdrzavanja">
                    <div className="opisGridItem">
                        <div className="naslov">
                            Što je to klasično "Time & Material" održavanje?
                        </div>
                        <div className="opis">
                            Klasično ili takozvano "Time & Material" održavanje je najbolje rješenje za održavanja i podršku uređajima nižeg ranga. Ovakav model održavanja uređaja se zasniva na pozivu korisnika (npr. kod zastoja ili kvara uređaja). Uređaje servisiramo prema propisanim standardima proizvođača. Servisnu intervenciju plaća korisnik po važećem cjenovniku, efektivno utrošeno radno vrijeme, zamjenu materijala (rezervni dijelovi i potrošni materijal) te putne troškove. Kod ovakvog načina održavanja koristimo isključivo originalne rezervne dijelove te potrošni materijal.
                            <br />
                            <br/>
                            <b>Provjerite našu ponudu i kvalitetu usluge. Vjerujemo da ćete biti ugodno iznenađeni.</b>
                        </div>
                    </div>
                    <div className="opisGridItem">
                        <div className="naslov">
                            Zašto se odlučiti za klasično "Time & Material" održavanje?
                        </div>
                        <div className="opis">
                            "Time & Material" održavanje je primjereno za manje zahtjevna produkcijska rješenja, gdje je količna uređaja manja. Također kod ovakvog načina održavanja uređaja, nudimo našim korisnicima mogućnost najma zamjenskog uređaja dok se njihov ne popravi. Korisnik plaća ugovorenu najamninu i broj otisaka napravljenih na uređaju. Facit d.o.o. za većinu aktualnih uređaja ima na zalihi rezervne dijelove i potrošni materijal.Osim izravnog održavanja nudimo i druge neizravne usluge, kao što je edukacija i obuka korisnika za korištenje uređaja.
                        </div>
                    </div>
                </div>



                <div className="vrstaOdrzavanja">
                    Ugovorno (FSMA) održavanje uređaja
                </div>
                <div className="opisVrsteOdrzavanja">
                    <div className="opisGridItem">
                        <div className="naslov">
                            Što je to ugovorno (FSMA) održavanje uređaja ?
                        </div>
                        <div className="opis">
                            FACIT doo nudi svojim kupcima u višem uredskom i produkcijskom okruženju optimalan model održavanja uređaja, na način potpunog održavanja sustava (Full Service Maintance Agreement - FSMA). Kupci prvenstveno cijene predvidljivost troškova održavanja, kao i mogućnost nabave rezervnih dijelova i servisne podrške te plaćanje prema stvarnoj potrošnji. FSMA održavanje podrazumijeva kompletnu servisnu podršku od potrošnog materijala, rezervnih dijelova do usluga servisnih tehničara. Drugim riječima, ovakav način održavanja opreme nudi korisniku kontinuirano ispravan i optimalan uređaja za rad.
                        <div style={{ marginTop: '1%', marginBottom: '1%' }}>
                                <br />
                                <b>Vrhunska oprema zahtijeva i vrhunsko održavanje</b>
                            </div>
                        Kada zahtijevate najbolje onda ste se odlučili za kupovinu najsuvremenije IT i uredske opreme. Logična odluka. Da bi vaša oprema radila što je duže moguće   preporučujemo da sa nama sklopite FSMA ugovor koji pruža učinkovitu tehničku pomoć, redovito profesionalno održavanje i popravak sa posebno kratkim vremenom odziva i rokom za popravak. Održavanje provodi isključivo naš autorizirani servis u skladu sa propisima proizvođača, svi rezervni dijelovi su originalni kao i kompletan potrošni materijal. Za rad u servisnom sektoru, postižemo posebno visoke ocjene.

                        </div>
                        <br></br>
                    </div>
                    <div className="opisGridItem">
                        <div className="naslov">
                            Zašto se odlučiti za ugovorno (FSMA) održavanje uređaja  ?
                        </div>
                        <div className="opis">
                            Predstavljamo Vam nekoliko najčešćih razloga zbog kojih se kupci odlučuju za održavanje uređaja po modelu ugovornog održavanja uređaja (FSMA): <br></br>
                            <ul class="dashed">
                                <li>Predvidljivost troškova neovisno od pokrivenosti ispisa bojom</li>
                                <li>Plaćanje po napravljenim otiscima</li>
                                <li>Garantira optimalnu raspoloživost uređaja</li>
                                <li>Kupac ne veže novac u zalihu potrošnog materijala koji je uključen u cijenu ispisa</li>
                                <li>Garantira vrijeme odaziva, u slučaju pogrešaka odnosno neispravnosti</li>
                                <li>Kratak nabavni rok za potrošni materijal te kratko vrijeme čekanja servisa</li>
                                <li>Garancija za djelovanje uređaja i za sve rezervne dijelove, kroz cjelokupno razdoblje trajanja ugovora </li>
                                <li>Potrebna zaliha rezervnih dijelova i potrošnog materijala kod FACIT-a te usluga servisa značajno skraćuje vrijeme popravka</li>
                                <li>Posebno poželjno kada je zahtjev kupca osiguravanje neometanog rada uređaja, ključno za poslovni proces kupca</li>
                            </ul>

                        </div>
                    </div>
                </div>




            </div>
        );
    }
};

export default Odrzavanje;