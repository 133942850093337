import React from 'react';
import { Layout } from 'antd';
import {
    InstagramOutlined,
    FacebookOutlined,
    LinkedinOutlined,
    ShoppingOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'

const { Footer } = Layout;

const MyFooter = () => {

    const iconStyle = { fontSize: '20pt', marginLeft: '2%' }
    return (
        <div style={{
            margin: '0 auto',
            backgroundColor: '#001529',
            width: '100%',
            bottom: '0px'
        }}>
            <Footer style={{
                textAlign: 'center',
                backgroundColor: '#001529',
                color: 'white',
                bottom: '0px'
            }}>

                <div>
                    E-mail:
            <a target="_blank" rel="noopener noreferrer" href="mailto:facit@facit.ba"> facit@facit.ba </a> |
            Telefon: +387 (0)33 229 335 |
            Fax: +387 (0)33 229 337 |
            Dr. Kasima Begića 23, 71 000 Sarajevo, Bosna i Hercegovina
            </div>
                <div style={{ marginTop: '10px', width: '100%' }}>
                    <Link style={iconStyle}
                        to={{ pathname: "https://www.facebook.com/facitsolutions/" }} target="_blank" rel="noopener noreferrer" >
                        <FacebookOutlined />
                    </Link>
                    <Link style={iconStyle}
                        to={{ pathname: "https://www.instagram.com/facitsarajevo/" }} target="_blank" rel="noopener noreferrer" >
                        <InstagramOutlined />
                    </Link>
                    <Link style={iconStyle}
                        to={{ pathname: " https://www.linkedin.com/company/facit-d-o-o-/about/" }} target="_blank" rel="noopener noreferrer" >
                        <LinkedinOutlined />
                    </Link>
                    <Link style={iconStyle}
                        to={{ pathname: "https://facit.olx.ba/" }} target="_blank" rel="noopener noreferrer" >
                        <ShoppingOutlined />
                    </Link>
                </div>
            </Footer>
        </div>
    )
}

export default MyFooter