import React, { Component } from 'react';
import DmSuscriptorImg from "../res/dmSuscriptor.png"

class DmSuscriptor extends Component {

    render() {
        return (
            <div>

            <div id="biblotekaContainer">

            <div className="vrstaOdrzavanja">
            Briga o vašim dokumentima ključna je za uspjeh bilo kojeg posla.
            </div>
            <div className="opisVrsteOdrzavanja">
                <div className="opisGridItem">
                    <div className="opis">
                    Integriranje rješenja za upravljanje sadržajem u vašem poslovanju može uroditi mnogim prednostima osiguravajući da vaše poslovanje uveliko poboljša nadzor nad dokumentima, sigurnost, pohranu, pronalaženje i ključno je u ispunjavanju regulatornih propisa.

                    <br/>
                    <br/>

                    Možemo savjetovati i pružiti vam ulaz u svijet usluga upravljanja dokumentima i rješenja koja vam olakšavaju pohranu, skeniranje, dohvaćanje i upravljanje dokumentima. Naš tim pomoći će vam u upravljanju cjelokupnim životnim ciklusom vaših dokumenata iz bilo kojeg izvora u kojem god formatu bili.

                    <br/>
                    <br/>

                    Poznavajući i razumijevajući različite potrebe korisnika i njihovih organizacija, naša obitelj proizvoda Suscriptor nudi razna rješenja koja su pripremljena za odgovarajuća okomita tržišta. Nadalje, nudimo i prilagođena poslovna rješenja prema zahtjevima kupaca, temeljena na moćnoj platformi.

                    <br/>
                    <br/>

                    Nudimo cjelovita rješenja za upravljanje sadržajem poduzeća. Od faza preliminarnog planiranja pa sve do obuke krajnjeg korisnika, nastojimo pružiti znanje i iskustvo kako bismo osigurali uspjeh vašeg projekta. Naš fokus je na inovacijama i poboljšanju poslovnih procesa usklađivanjem ljudi, procesa i sustava. Naši poslovni modeli osmišljeni su tako da odgovaraju vašim jedinstvenim zahtjevima, koristeći vodeća svjetska rješenja zajedno s našim proizvodima i uslugama.



                    </div>
                </div>
            </div>

            <img src={DmSuscriptorImg} alt="susciptor" style={{width: "40%"}} class="bibImage" />

            </div>
            </div>
        )
    }
};
export default DmSuscriptor;