import React from 'react';
import { Helmet } from 'react-helmet';
import { Card, List, Drawer, Tabs } from 'antd';
import { printeri, multifunkcionalniUredjaji, informatickaOprema, potrosniMaterijal, mreznaOprema } from '../res/Proizvodi/products.js';
import Logo from '../res/logo.png';
import $ from 'jquery';

import Header from '../res/Proizvodi/header2.jpg'

import {
    PhoneOutlined
} from '@ant-design/icons'

const { TabPane } = Tabs;

class Products extends React.Component {

    state = {
        visible: false,
        currentProducts: [...printeri, ...multifunkcionalniUredjaji],
        naziv: "Default title",
        slika: Logo,
        opis: "Default opis",
        cijena: 0,
        key: "1"
    };

    moveLeft = () => {
        let value = parseInt(this.state.key)
        this.setState({ key: value > 1 ? (value - 1).toString() : "1" }, () => { this.clickedMenu(this.state.key) })
    }

    showProductDrawer = (item) => {
        this.setState({ naziv: item.naziv });
        this.setState({ slika: item.slika });
        this.setState({ opis: item.opis });
        this.setState({ cijena: item.cijena });
        this.setState({ visible: true });
    }

    closeProductDrawer = () => {
        this.setState({ visible: false });
    }

    clickedMenu = (id) => {
        switch (id) {

            case '1':
                this.setState({ currentProducts: [...printeri, ...multifunkcionalniUredjaji], key: id });
                break;
            case '2':
                this.setState({ currentProducts: potrosniMaterijal, key: id });
                break;
            case '3':
                this.setState({ currentProducts: [...informatickaOprema, ...mreznaOprema], key: id });
                break;
            default:
        }

        $('#listaProizvodaWrapper').addClass('animateProductsList');

        setTimeout(() => {
            $('#listaProizvodaWrapper').removeClass('animateProductsList');
        }, 1000)
    }

    render() {
        return (
            <div id="containerGlavni">
                <div id="productsContainer">
                    <Helmet>
                        <title>Proizvodi | Facit</title>
                        <meta name="keywords" content="Xerox, Epson, Dell, printer, kopir uređaj, toner, tinta, digitalizacija, biblioteka, softver" />
                        <meta name="description" content="Raspolažemo impresivnom i raznovrsnom ponudom opreme za uredsku i produkcijsku upotrebu. Otvaranjem Print Shop-ova širom BiH, vršimo i digitalnu štampu po narudžbi." />
                        <meta name="robots" content="follow, index" />
                    </Helmet>

                    <div id="naslovNasaPrica">
                        <div id="divNaslovne">
                            <img id="naslovnaSlika"  src={Header} alt="background" onLoad={() => this.setState({ load: true })}></img>
                        </div>
                    </div>

                    <div id="proizvodiTabovi">
                        <Tabs defaultActiveKey="1" type="card" animated="true"
                            activeKey={this.state.key} onChange={this.clickedMenu}
                        >
                            <TabPane tab={
                                <div>
                                    <div style={{}}>
                                        UREĐAJI
                              </div>
                                </div>} key="1" />
                            <TabPane
                                tab={
                                    <div>
                                        <div>
                                            POTROŠNI MATERIJAL
                                    </div>
                                    </div>
                                } key="2" />


                            <TabPane tab={
                                <div>
                                    <div style={{}}>
                                        MREŽNA OPREMA
                              </div>
                                </div>}
                                key="3" />
                        </Tabs>
                    </div>

                    <div id="listaProizvodaWrapper">
                        <div id="listaProizvoda">
                            <List
                                grid={{ column: 3, gutter: 16 }}
                                dataSource={this.state.currentProducts}
                                renderItem={item => (
                                    <List.Item onClick={() => { this.showProductDrawer(item); }}>
                                        <div id="productWrapper" >
                                            <Card title={item.naziv} bordered={false}>
                                                <div id="slikaProizvodaWrapper" >
                                                    <img id="slikaProizvoda" src={item.slika} alt="Slika proizvoda"></img>
                                                </div>
                                                <div id="cijenaProizvoda">
                                                    Pozovi za cijenu <PhoneOutlined />
                                                </div>
                                            </Card>
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </div>

                    <Drawer
                        title={<span style={{ fontWeight: 'bold', color: '@dark-blue' }}>{this.state.naziv}</span>}
                        placement="right"
                        closable={false}
                        onClose={this.closeProductDrawer}
                        visible={this.state.visible}
                        width={window.innerWidth > 600 ? (window.innerWidth > 960 ? '30%' : '80%') : '80%'}
                    >
                        <div id="drawerContent" style={{ width: '95%', textAlign: 'center' }}>
                            <div id="slikaProizvodaWrapper" style={{ width: '250px', height: '250px', overflow: 'hidden', position: 'relative', background: 'white', margin: '0 auto' }}>
                                <img id="slikaProizvoda" src={this.state.slika} alt="Slika proizvoda" style={{ width: '100%', position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}></img>
                            </div>

                            <div id="cijenaProizvoda" style={{ width: '100%', textAlign: 'center', color: '#00407C', fontWeight: 'bold', marginBottom: '20px', marginTop: '10px' }}>
                                Pozovi za cijenu
                    </div>

                            <div id="opisProizvoda" style={{ textAlign: 'left', marginLeft: '20px' }}>
                                {this.state.opis.split("\n").map((i, key) => {
                                    let index = i.indexOf(':');
                                    let [first, second] = [i.slice(0, index), i.slice(index + 1)];
                                    return <div key={key}><b>{first}:</b> {second}</div>;
                                })}
                            </div>
                        </div>
                    </Drawer>


                </div>
            </div>
        );
    }
};

export default Products;
