import React from 'react';
import { Helmet } from 'react-helmet';
import Servisiranje from './servirisanje.js';
import Odrzavanje from './odrzavanje.js';
import { Tabs } from 'antd';
import {
    PhoneOutlined,
    ClockCircleOutlined,
    MailOutlined
} from '@ant-design/icons'
import Img from '../res/support.jpg'

const { TabPane } = Tabs;


class Support extends React.Component {
    state = {
        key: "1"
    }

    moveLeft = () => {
        let value = parseInt(this.state.key)
        this.setState({ key: value > 1 ? (value - 1).toString() : value.toString() })
    }

    moveRight = () => {
        let value = parseInt(this.state.key)
        this.setState({ key: value < 2 ? (value + 1).toString() : value.toString() })
    }

    changeTab = activeKey => {
        this.setState({
            key: activeKey
        });
    };


    render() {
        return (
            <div>
                <Helmet>
                    <title>Podrška | Facit</title>
                    <meta name="keywords" content="Xerox, Epson, Dell, printer, kopir uređaj, toner, tinta, digitalizacija, biblioteka, softver" />
                    <meta name="description" content="Pouzdanost, brzina i kvaliteta su značajke kojima težimo. U našem poduzeću svjesni smo da nakon prodaje, servisna podrška je od presudne važnosti za korisnika." />
                    <meta name="robots" content="follow, index" />
                </Helmet>
                <div id="containerGlavni">
                    <div id="sadrzaj">
                        <div id="naslovNasaPrica">
                            <div id="divNaslovne">
                                <img id="naslovnaSlika" src={Img} alt="background" style={{ width: "100%" }}></img>
                            </div>
                        </div>
                        <div id="podrskaTabovi">
                            <Tabs activeKey={this.state.key} defaultActiveKey="1" type="card" size="small" animated="true"
                                onChange={this.changeTab}  >
                                <TabPane tab="SERVISIRANJE" key="1">
                                    <Servisiranje />
                                </TabPane>
                                <TabPane tab="ODRŽAVANJE" key="2">
                                    <Odrzavanje />
                                </TabPane>
                            </Tabs>
                        </div>

                        <div id="gridSupport">
                            <div className="gridColumnItem">
                                <div className="countUpStyle">
                                    <PhoneOutlined />
                                </div>
                                <div className="countUpDescription">
                                    <b>Helpdesk</b> 033 229 332
                        </div>
                            </div>

                            <div className="gridColumnItem">
                                <div className="countUpStyle">
                                    <MailOutlined />
                                </div>

                                <div className="countUpDescription">
                                    <b>E-mail:</b> service@facit.ba
                        </div>
                            </div>

                            <div className="gridColumnItem">
                                <div className="countUpStyle">
                                    <ClockCircleOutlined />
                                </div>

                                <div className="countUpDescription">
                                    <b>Radno vrijeme servisa:</b>
                                </div>
                                <div className="countUpDescription">
                                    ponedjeljak – petak od 8:00 do 16:30 sati
                        </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        );
    }
};


export default Support;
