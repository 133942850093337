import React from 'react';

import XeroxServiceProvider from '../res/xerox-authorized-service-provider.png';
import EpsonServiceProvider from '../res/epson-authorized-service-provider.jpg';
import XeroxDocumentTechnologyPartner from '../res/xerox-authorized-document-technology-partner.png'

class Servisiranje extends React.Component {

    state = {}

    render() {
        return (
            <div id="odrzavanjeIServisiranjeContainer">

                <div className="vrstaOdrzavanja">
                    Pouzdanost, brzina i kvaliteta su značajke kojima težimo
                </div>
                <div className="opisVrsteOdrzavanja">
                    <div className="opisGridItem">
                        <div className="naslov">
                            Servisnom sektoru dajemo poseban značaj.
                        </div>
                        <div className="opis">
                            <br></br>
                            U našem poduzeću svjesni smo da nakon prodaje, servisna podrška je od presudne važnosti dobrog poslovnog odnosa. Stoga našem servisnom sektoru dajemo poseban značaj. Od prve instalacije te kroz cijeli životni vijek uređaja, servis je najbitniji prateći segment.
                            <br></br>
                            <br></br>
                            "Facit Service" stoji Vam na raspolaganju za sve hardverske i softverske probleme koji se javljuju na nekom uređaju. Servisnu službu sačinjavaju obrazovani stručnjaci sa višegodišnjim iskustvom u servisiranju IT opreme.
                            <br></br>
                            <br></br>
                            Osim otklanjanja kvarova i servisiranja u garantnom i vangarantnom periodu naš servis je u stanju ponuditi i ugovorno održavanje opreme. Garantujemo zamjenu neispravnih dijelova originalnim, i otklanjanje kvarova nastalih redovnom upotrebom opreme u najkraćem mogućem roku. Originalan potrošni materijal i rezervni dijelovi osiguravaju funkcionalnost, pravilan rad uređaja i iznad prosječan vijek trajanja.
                            <br></br>
                            <br></br>
                        </div>
                    </div>
                    <div className="opisGridItem">
                        <div className="naslov">
                            Naše glavne referencu su:
                        </div>
                        <br></br>
                        <div className="opis">
                            Autorizirani smo servis partner proizvođača Xerox i Epson. Korisnici naših usluga su mnoge državne institucije, obrazovne ustanove, mala i velika poduzeća. Kvalificirani smo za održavanje profesionalnih sustava za digitalnu štampu. Servisne usluge obavljamo u cijeloj Bosni i Hercegovini
                            <div id="xeroxPartnerImages">

                                <div className="xeroxImageWrapper" ><img src={XeroxServiceProvider} alt="Slika"></img></div>
                                <div className="xeroxImageWrapper"><img src={XeroxDocumentTechnologyPartner} alt="Slika"></img></div>
                                <div className="xeroxImageWrapper"><img src={EpsonServiceProvider} alt="Slika"></img></div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="vrstaOdrzavanja">
                    Želimo biti više od običnog pružatelja servisnih usluga
                </div>
                <div className="opisVrsteOdrzavanja">
                    <div className="opisGridItem">
                        <div className="naslov">
                            Nudimo svim korisnicima cjelovit niz usluga:
                        </div>
                        <div className="opis">
                            <ul class="dashed">
                                <li>Stručno i kvalitetno održavanje</li>
                                <li>Brzo vrijeme odziva</li>
                                <li>Mogućnost zamjene opreme za vrijeme popravka</li>
                                <li>Besplatan savjet</li>
                                <li>Projekcija i nadogradnja</li>
                                <li>Tehnička pomoć</li>
                                <li>Obuka korisnika za rad opreme</li>
                                <li>Dodatna usluga za poboljšanje performansi</li>
                            </ul>

                            <br />

                            Osigurano skladište rezervnih dijelova i potrošnog materijala daje vašoj opremi kontinuiran rad visokog kvaliteta. Stalna zaliha različitih rezervnih dijelova omogućuje nam brzo vrijeme odziva i najvišu razinu održavanja.  Naši servisni tehničari osposobljeni su za servis sljedećih proizvođača: <b> Xerox, Canon, Konica Minolta, Toshiba, Hewlett Pacard, Lexmark, Kyocera , Panasonic, Epson </b>
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="opisGridItem">
                        <div className="naslov">
                            Oprema koju servisiramo:
                        </div>
                        <div id="gridProizvodjaca">
                            <div className="gridProizvodjacaItem">Fotokopirni uređaji</div>
                            <div className="gridProizvodjacaItem">Produkcijski uređaji</div>
                            <div className="gridProizvodjacaItem">Multifunkcijski uređaji</div>
                            <div className="gridProizvodjacaItem">Pisači</div>
                            <div className="gridProizvodjacaItem">Fax uređaji</div>
                            <div className="gridProizvodjacaItem">Skeneri</div>
                            <div className="gridProizvodjacaItem">Projektori </div>
                            <div className="gridProizvodjacaItem">Monitori</div>
                            <div className="gridProizvodjacaItem">Osobni računari (Desktop)</div>
                            <div className="gridProizvodjacaItem">Prijenosni računari (Laptop)</div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Servisiranje;