import React, { Component } from 'react';
import YSoftLogo from "../res/Y-Soft/logo.png"

import OMNISeries from "../res/Y-Soft/omni_series.png"
import OMNIBridge from "../res/Y-Soft/omni_bridge.png"
import OMNIApps from "../res/Y-Soft/omni_apps.png"
import OMNIUP from "../res/Y-Soft/omni_up365.png"

import WORKFLOW from "../res/Y-Soft/workflow_solutions_platform.png"
import SafeQCloud from "../res/Y-Soft/safeq_cloud.png"
import SafeQCloudPro from "../res/Y-Soft/safeq_cloudPro.png"
import SafeQBreeze from "../res/Y-Soft/safeq_breeze.png"
import SafeQ from "../res/Y-Soft/safeq.png"

import SafeQBE3D from "../res/Y-Soft/safeq_be3d.png"
import SafeQBE3DAcademy from "../res/Y-Soft/be3d_academy.png"

class YSoft extends Component {

    render() {
        return (
            <div>

            <div id="biblotekaContainer">

            <div className="vrstaOdrzavanja">
            Glavna rješenja Y-soft proizvoda uključuju:
            </div>
            <div className="opisYSoft">
                <div className="opisYSoftGridItem">
                    <div className="opis">
                    <dl>
                        <dt>YSoft OMNI Series™</dt>
                            <dd>- YSoft OMNI Bridge™</dd>
                            <dd>- YSoft OMNI Apps™</dd>
                        <dt>YSoft SAFEQ™ Workflow Platform</dt>
                            <dd>- YSoft SAFEQ Cloud™</dd>
                            <dd>- YSoft SAFEQ™</dd>
                            <dd>- YSoft BE3D eDee™ Print Solution</dd>
                    </dl>
                    </div>
                </div>
                <div className="opisYSoftGridItem">
                    <div className="opis">
                    <img src={YSoftLogo} alt="Y-Soft logo" class="bibImage" />
                    </div>
                </div>
            </div>


            <div className="vrstaOdrzavanja">
            YSOFT OMNI RJEŠENJA
            </div>
            <div className="opisYSoft">
                <div className="opisYSoftGridItem">
                    <div className="opis">
                        <div id="YSoftOMNIImage">
                        <img src={OMNISeries} alt="YSoft OMNI Series" class="ySoftImage" />
                        </div>
                        <div>
                        YSoft OMNI Series trenutno i isplativo povezuje veliki broj pisača na tržištu sa Cloudom i Universal Printom. OMNI Series se sastoji od YSoft OMNI Bridge i YSoft OMNI Apps.
                        </div>
                        
                    </div>
                </div>

                <div className="opisYSoftGridItem">
                    <div className="opis">
                    <div id="YSoftOMNIImage">
                        <img src={OMNIBridge} alt="YSoft OMNI Bridge" class="ySoftImage" />
                        </div>
                        <div>
                        Edge uređaj bez poslužitelja, koji povezuje bilo koji umreženi pisač sa Cloudom.
                        </div>
                        
                        <br/>
                    </div>
                </div>

                <div className="opisYSoftGridItem">
                    <div className="opis">
                    <div id="YSoftOMNIImage">
                        <img src={OMNIApps} alt="YSoft OMNI Apps" class="ySoftImage" />
                        </div>
                        <div>
                        YSoft OMNI Apps pruža razne funkcije povezane sa ispisom za veliki broj pisača na tržištu.
                        </div>
                        
                    </div>
                </div>

                <div className="opisYSoftGridItem">
                    <div className="opis">
                    <div id="YSoftOMNIImage">
                        <img src={OMNIUP} alt="YSoft OMNI UP365" class="ySoftImage" />
                        </div>
                        <div>
                        OMNI UP365 podržava Universal Print technology od Microsoft-a.

                        YSoft OMNI Series je zvanično obavljena u Julu 2020. Dostupnost je usklađena sa generalnom dostupnošću Microsoftove tehnologije Universal Print. Međutim, trenutni pristup se može ponuditi u ograničenim područjima sve do opće upotrebe. 
                        </div>
                        
                    </div>
                </div>
            </div>


            <div className="vrstaOdrzavanja">
            YSOFT SAFEQ PLATFORMA
            </div>
            <div className="opisYSoft">
                <div className="opisYSoftGridItem">
                    <div className="opis">
                        <div>
                        Y Soft nudi inteligentna rješenja za radni proces poduzeća, koji pomažu firmama da grade pametnije poslovanje. U nastavku je tabela koja uključuje informacije o proizvodima, prodajne i marketinške materijale koji pomažu u vašem uspjehu tokom prodaje.

                        <br/>
                        <br/>

                        Platforma uključuje karakteristike koje su u skladu s vrijednostima YSoft SafeQ: poboljšanje produktivnosti zaposlenika, povećanje sigurnosti dokumenata i smanjenje troškova.
                
                        </div>
                    </div>
                </div>

                <div className="opisYSoftGridItem">
                    <div className="opis">
                        <div>
                        <img src={WORKFLOW} alt="YSoft Workflow Solutions Platform" class="ySoftWorkflowSolutions" />
                        </div>
                        
                    </div>
                </div>

                <div className="opisYSoftGridItem">
                    <div className="opis">
                        <div id="YSoftSafeQImage">
                        <img src={SafeQCloud} alt="YSoft Workflow Solutions Platform" class="ySoftWorkflow" />
                        </div>
                        <div>
                        YSoft SAFEQ Cloud je skup usluga baziranih na Cloudu. Svaka usluga sadrži komponente za upravljanje ispisom i/ili dokumente za uzimanje dokumenata, zajedno sa infrastrukturom za ispis koja ne zahtjeva IT upravljanje ili održavanje. Usluge YSoft SAFEQ Cloud servisa koriste Edge uređaj za sigurno, lokalnu obradu podataka. Buduće verzije pružit će Edge uređaj kao opciju.
                        </div>
                    </div>
                </div>

                <div className="opisYSoftGridItem">
                    <div className="opis">
                        <div id="YSoftSafeQImage"> 
                        <img src={SafeQCloudPro} alt="YSoft Workflow Solutions Platform" class="ySoftWorkflow" />
                        </div>
                        <div>
                        YSoft SAFEQ CloudPro pruža rezervnu infrastrukturu, razne mogućnosti elastičnosti i prilagođene opcije infrastrukture. Idealno za velike kompanije. 
                        </div>
                    </div>
                </div>

                <div className="opisYSoftGridItem">
                    <div className="opis">
                        <div id="YSoftSafeQImage">
                        <img src={SafeQBreeze} alt="YSoft Workflow Solutions Platform" class="ySoftWorkflow" />
                        </div>
                        <div>
                        YSoft SAFEQ Breeze (BETA) pruža zajedničku infrastrukturu što je čini idealnom za mala i srednja poduzeća (SMB). 
                        </div>
                    </div>
                </div>

                <div className="opisYSoftGridItem">
                    <div className="opis">
                        <div id="YSoftSafeQImage">
                        <img src={SafeQ} alt="YSoft Workflow Solutions Platform" class="ySoftWorkflow" />
                        </div>
                        <div>
                        YSoft SAFEQ je Workflow Solutions Platform. Tri glavna stuba platforme su: 2D upravljanje ispisom; hvatanje dokumenata i upravljanje 3D ispisom. YSoft SAFEQ se nudi u programskim paketima koji nude najbolju vrijednost povezanih modula. Ponuđen je i portfolio popratnih hardverskih rješenja.
                        </div>
                    </div>
                </div>

                <div className="opisYSoftGridItem">
                    <div className="opis">
                        <div id="YSoftSafeQImage">
                        <img src={SafeQBE3D} alt="YSoft Workflow Solutions Platform" class="ySoftWorkflow" />
                        </div>
                        <div>
                        YSoft BE3D eDee je 3D print solution dizajnirano za sigurnu i jednostavnu nastavu u sektoru obrazovanja. Y Soft nudi pakete za upravljanje 2D i 3D ispisom koji uključuje BE3D Academy, mrežni resurs 3D lekcija, treninga i baza podataka modela koja je dostupna kako kupcima tako i ne-kupcima. Kupci imaju ekskluzivan pristup premium 3D lekcijama i modulima s novim edukativnim lekcijama koje se dodaju kvartalno.
                        </div>
                    </div>
                </div>

                <div className="opisYSoftGridItem">
                    <div className="opis">
                        <div id="YSoftSafeQImage">
                        <img src={SafeQBE3DAcademy} alt="YSoft Workflow Solutions Platform" class="ySoftWorkflow" />
                        </div>
                        <div>
                        Internetska zbirka 3D planova lekcija namijenjenih nastavnicima. 3D planovi su projektni planovi koji su testirani od strane nasavnika i nude razne alate, uključujići datoteke 3D modela, video zapise, radne listove studenata i prezentacije za predstavljanje projekta studentima. Svaki plan 3D lekcije usklađen je sa ISTE standardom. 3D lekcije označene kao premium lekcije ekskluzivne su za korisnike Ysoft BE3D eDEE i kupce koji kupuju EDU paket.
                        </div>
                    </div>
                </div>

            </div>


            </div>
            </div>

            
        )
    }
};
export default YSoft;