import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import BibliotekaV2 from './bibliotekav2';
import BibliotekaV3 from './bibliotekav3';
import DmSuscriptor from './dmSuscriptor'
import Papercut from './papercut';
import YSoft from './y-soft';
import { Tabs } from 'antd';
import Code from '../res/cleanCode.jpg'

const { TabPane } = Tabs;

class Software extends Component {
    state = { load: false }

    render() {
        return (
            <div id="containerGlavni">
                <div id="softwareContainer">
                    <Helmet>
                        <title>Software | Facit</title>
                        <meta name="keywords" content="Xerox, Epson, Dell, printer, kopir uređaj, toner, tinta, digitalizacija, biblioteka, softver" />
                    <meta name="description" content="Software koji nudimo: Biblioteka v3.0, DM Suscriptor, Y-Soft i PaperCut, za sve potrebe oko vaših dokumenata, lakšeg printanja te pristupu svjetskoj biblioteci." />
                    <meta name="robots" content="follow, index" />
                    </Helmet>

                    <div id="naslovNasaPrica">
                        <div id="divNaslovne">
                            <img id="naslovnaSlika" src={Code} alt="background" onLoad={() => this.setState({ load: true })}></img>
                        </div>
                    </div>

                    <div id="SoftwareTabovi">
                        <Tabs defaultActiveKey="1" type="card" size="small" animated="true" style={{ maxWidth: "100vw" }}>

                            <TabPane tab="Biblioteka v2.0" key="1">
                                < BibliotekaV2 />
                            </TabPane>
                            <TabPane tab="Biblioteka v3.0" key="2">
                                <BibliotekaV3 />
                            </TabPane>
                            <TabPane tab="DM Suscriptor" key="3">
                                <DmSuscriptor />
                            </TabPane>

                            <TabPane tab="PaperCut" key="4">
                                <Papercut />
                            </TabPane>
                            <TabPane tab="Y-Soft" key="5">
                                <YSoft />
                            </TabPane>
                        </Tabs>

                    </div>
                </div>
            </div>
        )
    }
};
export default Software;
