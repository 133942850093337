import React from 'react';
import { Helmet } from 'react-helmet';
import 'react-typed/dist/animatedCursor.css';
import CountUp, { startAnimation } from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Button } from 'antd';
import { List } from 'antd';
import { Player } from 'video-react';

import Xerox from '../res/Partneri/xeroxBP.jpg';
import MC from '../res/Partneri/microsoftBP.png';
import Dell from '../res/Partneri/dellBP.png';
import USAID from '../res/Reference/USAID.png';
import mylan from '../res/Reference/mylan.jpg';
import unchr from '../res/Reference/unchr.png';
import banner from '../res/bannerHome.jpg';
import Map from '..//res/map.jpg'
import Logo from '../res/logoHome.png';
import { Link } from 'react-router-dom'

import HomeVideo from '../res/Videos/xerox_video_1080p.mp4'

import {
    CalendarOutlined,
    SmileOutlined,
    PrinterOutlined,
    LaptopOutlined,
    FileDoneOutlined,
    EnvironmentOutlined
} from '@ant-design/icons'

class Home extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.onVisibilityChange = this.onVisibilityChange.bind(this); // Bind for appropriate 'this' context
    }

    partneri = [
        {
            src: Dell,
            alt: "Dell",
            link: "https://www.dell.com"
        },
        {
            src: MC,
            alt: "Microsoft",
            link: "https://www.microsoft.com/bs-ba/"
        },
        {
            src: Xerox,
            alt: "Xerox",
            link: "https://www.xerox.com/"
        }
    ]

    reference = [
        {
            src: USAID,
            alt: "USAID",
            link: "https://www.usaid.gov/"
        }, {
            src: unchr,
            alt: "UNHCR",
            link: "https://www.unhcr.org/"
        },
        {
            src: mylan,
            alt: "MYLAN",
            link: "https://www.mylan.com/"
        }

    ]

    state = {
        load: true
    }

    handleStateChange(state, prevState) {
        this.setState({
            player: state,
            currentTime: state.currentTime
        });
    }

    onVisibilityChange(isVisible) {
        if (isVisible) {
            startAnimation(this.myCountUp);
        }
    }

    play() {
        this.player.play();
    }

    render() {

        return (
            <div id="containerGlavni">
                <Helmet>
                    <title>Naslovna | Facit</title>
                    <meta name="keywords" content="Xerox, Epson, Dell, printer, kopir uređaj, toner, tinta, digitalizacija, biblioteka, softver" />
                    <meta name="description" content="Xerox - servisni partner vodećeg proizvođača opreme i naprednih rješenja za proizvodnju i upravljanje dokumentima." />
                    <meta name="robots" content="follow, index" />
                </Helmet>
                <div id="typedText" style={{display:"none"}}>
                    {this.state.load && <div id="typedTextContainer">
                        <img id="onVideoLogo" src={Logo} alt="Logo" class="logoHome" />
                        <div id="sloganText">
                            DIO VAŠEG TIMA
                        </div>

                    </div>}
                </div>
                {this.state.load && <div>
                    <div className="videoStyle">
                        <Player
                            fluid={true}
                            playsInline
                            poster="/assets/poster.png"
                            src={HomeVideo}
                            autoPlay={true}
                            loop={true}
                            muted
                        />
                        <div id="flag"></div>
                    </div>

                    <div id="mobile_slogan_text">  DIO VAŠEG TIMA </div>


                    <div id="gridStatistics">
                        <div className="gridColumnItem">
                            <div className="countUpStyle">
                                <CalendarOutlined />
                                <span>    </span>
                                <CountUp end={11} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>)}
                                </CountUp>
                            </div>
                            <div className="countUpDescription">
                                Godina uspješnog poslovanja
                    </div>
                        </div>

                        <div className="gridColumnItem">
                            <div className="countUpStyle">
                                <FileDoneOutlined />
                                <span>    </span>
                                <CountUp end={350} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            +
                               </div>

                            <div className="countUpDescription">
                                Uspješno riješenih servisnih zahtjeva mjesečno
                          </div>
                        </div>

                        <div className="gridColumnItem">
                            <div className="countUpStyle">
                                <SmileOutlined />
                                <span>    </span>
                                <CountUp end={1154} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </div>

                            <div className="countUpDescription">
                                Zadovoljnih klijenata
                        </div>
                        </div>
                        <div className="gridColumnItem">
                            <div className="countUpStyle">

                                <LaptopOutlined />
                                <span>    </span>
                                <CountUp end={50000} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                        +
                        </div>
                            <div className="countUpDescription">
                                Isporučenih računara, laptopa i multifunkcionih printera
                        </div>
                        </div>
                        <div className="gridColumnItem">
                            <div className="countUpStyle">
                                <PrinterOutlined />
                                <span>    </span>
                                <CountUp end={50} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            +
                        </div>

                            <div className="countUpDescription">
                                Aktivnih produkcijskih uređaja na projektima rente
                        </div>

                        </div>
                    </div>

                    <div id="partneriHome">
                        <h1 style={{ textAlign: 'center' }}> NAŠI PARTNERI</h1>
                        <div id="gride" style={{ textAlign: 'center' }}>
                            <List
                                grid={{
                                    gutter: 16,
                                    column: 9,
                                    justify: 'center'
                                }}

                                dataSource={this.partneri}
                                renderItem={item => (
                                    <div id="referenca" >
                                        <a href={item.link} target="_blank" rel="noopener noreferrer" >
                                            <img id="slike" src={item.src} alt={item.alt} key={item.src} />
                                        </a>

                                    </div>
                                )}
                            />
                        </div>

                        <div id="showAllButton">
                            <Link to="/partners">
                                <Button id="dugme" type="primary" >Prikaži sve</Button>
                            </Link>
                        </div>

                        <div id="homeImageDivider" style={{ marginTop: '5%', marginBottom: '5%' }}>
                            <img src={banner} alt="Banner" style={{ width: '100%', filter: 'blur(3px)' }} />
                        </div>
                    </div>

                    <div id="referenceHome">
                        <h1 style={{ textAlign: 'center' }}> NAŠE REFERENCE</h1>
                        <div id="gride">
                            <div style={{ textAlign: 'center' }}>
                                <List
                                    grid={{
                                        gutter: 9,
                                        column: 3,
                                        justify: 'center'
                                    }}

                                    dataSource={this.reference}
                                    renderItem={item => (
                                        <div id="referenca" >
                                            <a href={item.link} target="_blank" rel="noopener noreferrer" >
                                                <img id="slike" src={item.src} alt={item.alt} key={item.src} />
                                            </a>

                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div id="showAllButton">
                            <Link to="/references">
                                <Button id="dugme" type="primary" >Prikaži sve</Button>
                            </Link>
                        </div>

                    </div>


                    <div id="homeLocation">
                        <a target="_blank" rel="noopener noreferrer"
                            href="https://www.google.com/maps/place/Facit+d.o.o./@43.8408702,18.3325361,17z/data=!4m5!3m4!1s0x4758c929efc7f57d:0xe752e9e3a5f54102!8m2!3d43.8408818!4d18.3315383">
                            <EnvironmentOutlined />
                        </a>
                        <br />

                        <div className="locationText">
                            POSJETITE NAS
                        </div>
                    </div>

                    <a style={{ marginLeft: '10%' }} id="homeMap" target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Facit+d.o.o./@43.8408702,18.3325361,17z/data=!4m5!3m4!1s0x4758c929efc7f57d:0xe752e9e3a5f54102!8m2!3d43.8408818!4d18.3315383">
                        <div style={{ marginTop: '5%' }}>
                            <img id="naslovnaSlika" src={Map} alt="Lokacija" style={{ width: '100%' }} />
                        </div>
                    </a>
                </div>}
            </div>
        );
    }
};

export default Home;
