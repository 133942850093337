import React from 'react';
import { Helmet } from 'react-helmet';
import Kontakti from './kontakti'
import NasaPrica from './nasaPrica'
import Story from '../res/story.jpg'
import { Tabs } from 'antd';

const { TabPane } = Tabs;

class About extends React.Component {
    state = {
        load: true,
        key: "1"
    }

    moveLeft = () => {
        let value = parseInt(this.state.key)
        this.setState({ key: value > 1 ? (value - 1).toString() : value.toString() })
    }

    moveRight = () => {
        let value = parseInt(this.state.key)
        this.setState({ key: value < 2 ? (value + 1).toString() : value.toString() })
    }

    changeTab = activeKey => {
        this.setState({
            key: activeKey
        });
    };

    render() {
        return (
            <div>

                <Helmet>
                    <title>O nama | Facit</title>
                    <meta name="keywords" content="Xerox, Epson, Dell, printer, kopir uređaj, toner, tinta, digitalizacija, biblioteka, softver" />
                    <meta name="description" content="FACIT je multibrand IT kompanija, koja se prateći trendove na tržištu profilisala kao lider kvaliteta, stručnosti i profesionalnosti. Osnovani smo 2009. godine, te smo do današnjeg dana pažljivo i vrijedno ulagali u naše osobolje, te proširili naš portifolij u više IT pravaca. Odlučili smo biti istrajni u njegovanju tradiocionalnih vrijednosti, ne zaboravljajući da je mladalački entuzijazam pokretač najvećih promjena." />
                    <meta name="robots" content="follow, index" />
                </Helmet>
                <div id="containerGlavni">
                    <div id="naslovNasaPrica">
                        <div id="divNaslovne">
                            <img id="naslovnaSlika"  src={Story} alt="background" onLoad={() => this.setState({ load: true })}></img>
                        </div>
                    </div>
                    {this.state.load && <div>
                        <div id="aboutTabovi">
                            <Tabs activeKey={this.state.key} defaultActiveKey="1" type="card" size="small" animated="true"
                                onChange={this.changeTab}>
                                <TabPane tab="NAŠA PRIČA" key="1">
                                    < NasaPrica />
                                </TabPane>
                                <TabPane tab="NAŠ TIM" key="2">
                                    <Kontakti />
                                </TabPane>
                            </Tabs>
                        </div>
                        <div id="newText">
                            <p style={{ fontStyle: "italic" }}>
                                <hr></hr>
                Integrišući profesionalnost, pouzdanost i povjerenje postavljamo visoke standarde u razvijanju partnerskih odnosa sa kupcima i posvećeni smo prevazilaženju njihovih očekivanja. Želimo biti prepoznatljivi po izvrsnosti i kvalitetu usluga. Usmjereni ka istinskim vrijednostima postavljamo korisnike u vrh naše organizacione strukture, a mi se trudimo biti postojan dio njihovog tima.
                <hr></hr>
                            </p>
                        </div>
                    </div>}
                </div>
            </div >
        );
    }
};

export default About;
