import React from 'react';
import { List, Card } from 'antd';

import {
    MailOutlined,
    PhoneOutlined,
    UserOutlined
} from '@ant-design/icons'

import AlenBukva from "../res/Radnici/AlenBukva.jpg"
import KenanDelalic from "../res/Radnici/KenanDelalic.jpg"
import LamijaDelalic from "../res/Radnici/LamijaDelalic.jpg"
import NedimMilanovic from "../res/Radnici/NedimMilanovic.jpg"
import RuzminVelic from "../res/Radnici/RuzminVelic.jpg"
import SalkoKrpo from "../res/Radnici/SalkoKrpo.jpg"


const divStyle = {
    margin: "5%",
    textAllign: "center"
}
const Kontakti = () => {

    const data = [
        {
            title: 'Direktor',
            name: 'Delalić Lamija',
            image: LamijaDelalic,
            mail: 'lamijam@facit.ba',
            broj: '061/092-738'
        },
        {
            title: 'Voditelj servisa',
            name: 'Milanović Nedim',
            image: NedimMilanovic,
            mail: 'nedimm@facit.ba',
            broj: '061/159-415'
        },
        {
            title: 'Voditelj prodaje',
            name: 'Delalić Kenan',
            image: KenanDelalic,
            mail: 'kenand@facit.ba',
            broj: '062/746-108'
        },
        {
            title: 'Office Branch Manager',
            name: 'Bukva Alen',
            image: AlenBukva,
            mail: 'alenb@facit.ba',
            broj: '061/244-884'
        },
        {
            title: 'Serviser',
            name: 'Krpo Salko',
            image: SalkoKrpo,
            mail: 'salkok@facit.ba',
            broj: '061/138-322'
        },
        {
            title: 'Serviser',
            name: 'Velić Ruzmin',
            image: RuzminVelic,
            mail: 'ruzminv@facit.ba',
            broj: '061/190-529'
        },
    ];

    return (

        <div id = "container">

            <div id="teamListWrapper" style={divStyle}>
                <List
                    grid={{ gutter: 18, column: 4 }}
                    dataSource={data}
                    renderItem={item => (
                        <List.Item>
                            <div id="aboutTeamWrapper">
                            <div >
                                <div id="aboutTeamImageWrapper">
                                    <img id="aboutTeamImage" src={item.image} alt="Radnik" style= {{objectFit: "cover",   width: "100%"}}  />
                                </div>
                            </div>
                            <Card title={item.name} style={{ textAlign: 'center' }}>
                            
                            
                                <div id="radnikInfo">
                                    <UserOutlined /> {item.title}
                                    <br />
                                    <a target="_blank" rel="noopener noreferrer" href={"mailto:" + item.mail}>
                                        <MailOutlined />
                                    </a>
                                    <span> </span>{item.mail}
                                    <br />
                                    <PhoneOutlined /> {item.broj}
                                </div>
                            </Card>
                        </div>
                        </List.Item>
                    )}
                />

            </div>

        </div>

    );
};

export default Kontakti;
