import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import RentaImg from '../res/renta.jpg'
import { AiOutlineRight } from "react-icons/ai";

class Renta extends Component {
    state = { load: false }

    render() {
        return (
            <div id="containerGlavni">
                <div id="softwareContainer">
                    <Helmet>
                        <title>Renta | Facit</title>
                        <meta name="keywords" content="Xerox, Epson, Dell, printer, kopir uređaj, toner, tinta, digitalizacija, biblioteka, softver" />
                        <meta name="description" content="Najam printera i plaćanje ispisa „po kliku” je oblik usluge koji je već neko vrijeme zastupljen u svijetu, a taj trend koji donosi znatne uštede." />
                        <meta name="robots" content="follow, index" />
                    </Helmet>

                    <div id="naslovNasaPrica">
                        <div id="divNaslovne">
                            <img id="naslovnaSlika" src={RentaImg} alt="background" onLoad={() => this.setState({ load: true })}></img>
                        </div>
                    </div>

                    <div id="biblotekaContainer">
                        <div className="vrstaOdrzavanja renta">
                            PODRŠKA ZA ISPIS KAD GOD, I GDJE GOD JE ZATREBATE!
                     </div>

                        <div className="opisVrsteOdrzavanja">
                            <div className="opisGridItem">
                                <div className="opis">
                                    Štampa je naša strast i nešto što radimo 11 godina. Trudimo se da kroz naš posao svim našim klijentima i partnerima uz uslugu rente pružimo veću vrijednost, preciznu kontrolu i kontinuirano usavršavanje, i najbitnije, olakšamo poslovanje.
                               </div>
                                <br />
                                <div className="opis">
                                    Nudimo vam provjerenu opremu svjetskog lidera u štampi, kvalitetan ispis i podršku 24/7 potrebnu za izradu dokumenata na najprofesionalniji i najučinkovitiji mogući način.
                               </div>

                            </div>
                        </div>

                        <div className="vrstaOdrzavanja">
                            Nudimo:
                          </div>
                        <div className="opisVrsteOdrzavanja">
                            <div className="opisGridItem">
                                <div className="opis opisBold">

                                    <AiOutlineRight /> Portfolio kopirnih uređaja, pisača, višenamjenskih uređaja i proizvodne opreme svjetske klase

                                    <br />

                                    <AiOutlineRight /> Varijabilne ugovore najma fotokopirnih uređaja i pisača – od mjesec dana do tri godine

                                    <br />

                                    <AiOutlineRight />Tim stručnjaka koji će vam pomoći u planiranju, instalaciji, konfiguraciji i radu

                                    <br />

                                    <AiOutlineRight /> Najjeftinju cijenu ispisa na tržištu

                                    <br />
                                    <br />

                                    <div className="opis">
                                        Kada ste partner s nama, dobivate više od najamnine pisača. Bez obzira na vaše potrebe za ispisom, ovdje smo kako bismo bili sigurni da ste spremni na to.
                                     </div>

                                    <br />
                                    <div className="opis">
                                        Bez početnog ulaganja unajmite profesionalni printer, MFP i fotokopirni uređaj za ispise u boji ili crno/bijeli ispis. Zaboravite sve brige oko skupih servisa i troškove oko potrošnog materijala. Intervencije određujemo mi, brzo i bez dodatne naplate! Bez brige oko nabavke tonera, mi pratimo Vašu potrošnju i isporučujemo tonere bez dodatne naplate.
                                 </div>

                                </div >
                            </div>
                        </div>


                        <div className="vrstaOdrzavanja">
                            Izaberite neku od opcija rente koja vam odgovara i javite nam se za ponudu:
                           </div>
                        <div className="opisVrsteOdrzavanja">
                            <div className="opisGridItem">
                                <div className="opis">
                                    <AiOutlineRight /> <b>	Rent + Click </b>

                                    <br />
                                    Unajmite uređaj i plaćajte fiksnu mjesečnu naknadu za korištenje uređaja uz unaprijed ugovoreni broj ispisa i kopija, shodno analizi vaših potreba. Bez dodatnih skrivenih troškova!

                                    <br />
                                    <br />
                                    <AiOutlineRight /> 	<b> All in Click </b>

                                    <br />
                                    Idealno za velike urede! Bez mjesečne naknade, uživajte u ispisu uz naplatu po kliku!
                                    <br />
                                    <br />
                                    <AiOutlineRight /> <b> Purchase + Click </b>

                                    <br />
                                    Kupite idealan uređaj za vaše potrebe uz povlaštene uvjete i uživajte u prednostima servisnog održavanja, brigu prepustite nama!

                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};
export default Renta;
