import React, { Component } from 'react';
import BiblliotekaImg from "../res/biblioteka_v3.jpg"

class BibliotekaV3 extends Component {

    render() {
        return (
            <div>

            <div id="biblotekaContainer">

            <div className="vrstaOdrzavanja">
                Razlika između verzija
            </div>
            <div className="opisVrsteOdrzavanja">
                <div className="opisGridItem">
                    <div className="opis">
                    Osnovna razlika verzije v3.0 u odnosu na verziju v2.0 kad je u pitanju obrada bibliotečke građe je UNIMARC standard koji je u verziji v3.0 u potpunosti implementiran. Dodatno poboljšanje je i kompletan sistem pomoći obrade bibliotečke građe po UNIMARC standardu. Korisnik u određenom trenutku na klik misem može dobiti pomoć za oznaku, potpolje i indikatore. Ova pomoć obuhvata i konkretne primjere korištenja UNIMARC oznaka prilikom obrade bibliotečke građe.
                    </div>
                </div>
            </div>

            <div className="vrstaOdrzavanja">
                Obrada bibliotečke građe
            </div>
            <div className="opisVrsteOdrzavanja">
                <div className="opisGridItem">
                    <div className="opis">
                    Jednostavnost korištenja i brzina obrade bibliotečke građe glavne su odlike modula za obradu bibliotečke građe unutar sistema FACIT Biblioteka v2.0 Zahvaljujući uzajamnom katalogu www.biblioteka.ba korisnici imaju na raspolaganju veliki broj zapisa tako da se obrada bibliotečke građe može svesti na samo par klikova mišem za preuzimanje zapisa iz uzajamnog kataloga i unos lokalnog inventarnog broja bibliotečke građe. Korisniku je također na raspolaganju lista od skoro 600 svjetskih biblioteka za pretraživanje i preuzimanje zapisa.
                        Korisnik ne mora poznavati bibliotečke standarde da bi sasvim korektno obradio bibliotečku građu unutar sistema FACIT Biblioteka v2.0 koji na kraju, automatizmom, generiše kataloški opis. Svi zapisi obrađeni kroz sistem FACIT su od početka rada sa sistemom isključivo Vaše vlasništvo i niko nema pravo da bez vašeg znanja i dozvole raspolaže Vašim podacima.
                    </div>
                </div>
            </div>

            <div className="vrstaOdrzavanja">
                Cirkulacija građe
            </div>
            <div className="opisVrsteOdrzavanja">
                <div className="opisGridItem">
                    <div className="opis">
                    Cirkulacija bibliotečke građe je proces koji je u potpunosti podržan od strane sistema FACIT Biblioteka v2.0. Korisnici na jednom mjestu imaju na rapolaganju sve informacije potrebne za obavljanje svih akcija vezanih za cirkulaciju bibliotečke građe. Lociranje člana je moguće na više načina od kojih je napraktičniji i najbrži korištenjem bar kod čitača i članskih kartica. Lociranje jedinice bibliotečke građe je također moguće pomoću bar kod čitača i naljepnice na knjizi. Modul za cirkulaciju podržava i rezervacije bibliotečke građe, prilikom čega sistem vodi računa o redu čekanja. Sistem slanja upozorenja je također na raspolaganju korisnicima za kontrolu i kašnjenja vraćanja bibliotečke građe. Kad je u pitanju evidencija članstava, sistem FACIT Biblioteka pruža mogućnost detaljne evidencije podataka o članovima i članstvima i u skladu sa tim velik set izvještaja vezanih uz članove, članstva i aktivnosti.
                    </div>
                </div>
            </div>

            <div className="vrstaOdrzavanja">
            Izvještaji
            </div>
            <div className="opisVrsteOdrzavanja">
                <div className="opisGridItem">
                    <div className="opis">
                    Mogućnosti izvještavanja i analiza su, zahvaljujući velikoj prilagodljivosti načina prikaza podataka, praktično neograničene, budući da korisnik, zahvaljujući velikoj prilagodljivosti tabličnih prikaza podataka, može sam kreirati i snimiti bilo koji izvještaj vezan za bilo koji segment bibliotečkog poslovanja.
                        Svaki tablični prikaz moguće je prilagoditi trenutnim potrebama korisnika. Sve podatke je moguće grupirati, sortirati, filtrirati, ispisati ili eksportovati u neki od standardnih formata Xls ili Pdf. Kreirane postavke tabličnog prikaza moguće je snimiti i kasnije samo primjenjivati. Pored ovakvog načina analize podataka postoji standardni set izvještaja o bibliotečkom fondu, cirkulaciji građe, članovima, nabavci, otpisu, naljepnice i članske kartice, itd.
                        Svaki od standardnih izvještaja moguće je prilagoditi potrebama korisnika korištenjem dizajnera izvještaja.
                    </div>
                </div>
            </div>

            <div className="vrstaOdrzavanja">
            Uzajamni katalog
            </div>
            <div className="opisVrsteOdrzavanja">
                <div className="opisGridItem">
                    <div className="opis">
                    Uzajamni katalog www.biblioteka.ba je mjesto gdje se prikupljaju podaci iz sistema FACIT Biblioteka. Naravno, na korisniku je da odluči da li će ti podaci biti dostupni na portalu www.biblioteka.ba ili ne. U svakom slučaju podaci koje je moguće poslati na FACIT portal su informacije o biblitečkom fondu, informacije o članovim i članstvima, analitički podaci, podaci o dezideratima itd.
                        Sistem autorizacije korisnika portala www.biblioteka.ba definiše koji podaci će biti javni, a koji će biti dostupni samo ovlaštenim korisnicima. Tako na primjer, posjetioci portala imaju mogućnost pretraživanja fondova biblioteka članica sistema FACIT, dok recimo bibliotekari koji imaji svoje pristupne podatke (korisničko ime i šifra) mogu vidjeti sve podatke koje su postavili na portal www.biblioteka.ba, mogu ih mijenjati, brisati itd.
                        Jedna od prednosti ovakvog načina rada je mogućnost ovlaštenih osoba iz ministarstva da analiziraju stanje fonda i aktivnosti u školskim bibliotekama i da zahvaljujući tim podacima poboljšaju i optimiziraju rad školskih biblioteka. Na primjer, informacije o dezideratima svih škola na jednom mjestu u svakom slučaju mogu pomoći kod popunjavanje fondova bibliotečke građe u školskim bibliotekama.
                    </div>
                </div>
            </div>

            <div className="vrstaOdrzavanja">
            Prilagodljivost
            </div>
            <div className="opisVrsteOdrzavanja">
                <div className="opisGridItem">
                    <div className="opis">
                    Prilagodljivost sistema FACIT se ogleda u svim segmentima. Sistem je preveden na više jezika, korisnik može mijenjati izgled svih izvještaja pomoću dizajnera izvještaja, svi tablični prikazi podataka su također prilagodljivi. Svi parametri vezani za cirkulaciju građe, kao što su maksimalan broj knjiga koje se mogu iznajmiti, broj dana koliko se mogu zadržati, koliko dana vrijedi rezervacija, da li se i koliko plaća članstvo, koliko traje članstvo, itd. se također mogu prilagoditi načinu poslovanja biblioteke. Sistem također pruža mogućnost korisniku da aktivno učestvuje u poboljšanju sistema kroz slanje prijedloga, kritika i pohvala prozvođaču softvera nakon čega slijedi automatska nadogradnja sistema putem interneta.
                    </div>
                </div>
            </div>


            <img src={BiblliotekaImg} alt="bibliotekaV3" class="bibImage" />

            </div>
            </div>
        )
    }
};
export default BibliotekaV3;