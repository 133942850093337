import React, { Component } from 'react';

import IM1 from "../res/Papercut/image1.png"
import IM2 from "../res/Papercut/image2.png"
import IM3 from "../res/Papercut/image3.png"
import IM4 from "../res/Papercut/image4.png"
import IM5 from "../res/Papercut/image5.png"
import IM6 from "../res/Papercut/image6.png"

class Papercut extends Component {

    render() {
        return (

            <div>

            <div id="biblotekaContainer">

            <div className="vrstaOdrzavanja">
            DA LI VAM PRINTANJE STVARA POTEŠKOĆE? Jednostavno ispisujte s bilo kojeg uređaja i bilo kada!
            </div>
            <div className="opisVrsteOdrzavanja">
                <div className="opisGridItem">
                    <div className="opis">
                    Ispis je jedan od dosadnijih dijelova IT-a jer bi jednostavno to trebao i biti, jer bi trebao "samo funkcionirati". PaperCut vam omogućava da ponovno pojednostavite ispis krajnjim korisnicima, bilo da ispisuju s BYOD-a ili mobilnog uređaja, i olakšava vam život automatskim postavljanjem upravljačkih programa za ispis. U ponudi imamo još puno opcija, od vodećih neovisnih integracija u industriji do jednostavnog ispisivanja Find-Me pritiskom i otpuštanjem.
                    </div>
                </div>
            </div>

            <div className="softwareSlika">
            <img style={{ width: "35vw", marginBottom:"20px" }} src={IM1} alt="susciptor" class="bibImage" />
            </div>

            <div className="vrstaOdrzavanja">
            ŠTA JE BILO NA VAŠEM POSLJEDNJEM NESORTIRANOM ŠTAMPANJU? Osigurajte sve dokumente prije, tijekom i nakon ispisa!
            </div>
            <div className="opisVrsteOdrzavanja">
                <div className="opisGridItem">
                    <div className="opis">
                    Organizacije troše bogatstvo štiteći svoje digitalne podatke, ali curenje može biti jednostavno kao ostavljanje dokumenta na pisaču. Kontrolirajte pristup uređaju, primijenite sigurno izdanje ispisa ili čak digitalno potpišite i vodite žigove zadataka ispisa kako biste sigurnost podigli na novu razinu.
                    </div>
                </div>
            </div>

            <div className="softwareSlika">
            <img style={{ width: "35vw" }} src={IM2} alt="susciptor" class="bibImage" />
            </div>
            
            <div className="vrstaOdrzavanja">
            JE LI VAŠ POSLJEDNJI POSAO BIO DVOSTRAN? Smanjite trošak svog ispisa i svoje račune!
            </div>
            <div className="opisVrsteOdrzavanja">
                <div className="opisGridItem">
                    <div className="opis">
                    Smanjenje otpada i kontrola troškova nisu u korištenju ˝štapa˝, već u suptilnoj promjeni ponašanja korisnika (˝štap˝ može pomoći!). PaperCut je prepun značajki poput poticanja dvostranog ispisa, primjene razumnih kvota korištenja i cjelovitih pravila plaćanja za ispis i povratj troškova ispisa.

                    <br/>
                    <br/>

                    Prilikom anketiranja naših kupaca otkrili smo da im je primjena samo jedne značajke, poput sigurnog izdanja za ispis, pomogla uštedjeti čak 15%.
                    </div>
                </div>
            </div>
            
            <div className="softwareSlika">
            <img style={{ width: "35vw" }} src={IM3} alt="susciptor" class="bibImage" />
            </div>


            <div className="vrstaOdrzavanja">
            PROIZVODI? Bilo koja marka, bilo koja platforma, sva okruženja!
            </div>
            <div className="opisVrsteOdrzavanja">
                <div className="opisGridItem">
                    <div className="opis">
                    Nije važno koje je veličine vaša organizacija, koje pisače koristite ili koji operativni sustav vaši korisnici preferiraju - PaperCut je za vas. Zastupamo tehnologiju na više platformi, neutralnu prema dobavljaču, da bismo isporučili rješenje za upravljanje ispisom koje jednostavno funkcionira.

                    <br/>
                    <br/>

                    Kao tehničari volimo Windows, Linux, Mac, Chromebook, Android, iOS i da ... čak i Novell.
                    </div>
                </div>
            </div>

            <div id="paperCutImages">
                <div className="paperCutImagesItem">
                <img style={{ width: "90%" }} src={IM4} alt="susciptor" class="bibImage" />
                </div>
                <div className="paperCutImagesItem">
                <img style={{ width: "90%" }} src={IM5} alt="susciptor" class="bibImage" />
                </div>
                <div className="paperCutImagesItem">
                <img style={{ width: "90%" }} src={IM6} alt="susciptor" class="bibImage" />
                </div>
            </div>

            </div>
            </div>
        )
    }
};
export default Papercut;