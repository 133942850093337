import React, { Component } from 'react';
import { Menu, Dropdown } from 'antd';
import { Link } from "react-router-dom";
import Logo from '../res/logo.png';
import { withRouter } from 'react-router-dom'
import {
    MailOutlined,
    EnvironmentOutlined,
    MenuUnfoldOutlined
} from '@ant-design/icons'

class MyMenu extends Component {

    state = { currentLocation: '' }

    clickedMenu = () => {
        let href = window.location.href.split('/');
        href = href[href.length - 1];
        this.setState({ currentLocation: href });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.clickedMenu()
            window.scrollTo(0, 0)
        }
    }

    componentWillMount() {
        this.clickedMenu()
    }

    render() {

        const menu = (
            <Menu id="menu" theme="dark" mode="horizontal"
                defaultSelectedKeys={['/' + this.state.currentLocation]}
                selectedKeys={['/' + this.state.currentLocation]}
                onClick={() => { this.clickedMenu(); }}>

                <Menu.Item id="menuItem" key='/'>
                    <Link to="/">
                        Naslovna
            </Link>
                </Menu.Item>

                <Menu.Item id="menuItem" key='/products'>
                    <Link to="/products">
                        Proizvodi
            </Link>
                </Menu.Item>

                <Menu.Item id="menuItem" key='/software'>
                    <Link to="/software">
                        Software
            </Link>
                </Menu.Item>

                <Menu.Item id="menuItem" key='/renta'>
                    <Link to="/renta">
                        Renta
            </Link>
                </Menu.Item>

                <Menu.Item id="menuItem" key='/support'>
                    <Link to="/support">
                        Podrška
            </Link>
                </Menu.Item>


                <Menu.Item id="menuItem" key='/partners'>
                    <Link to="/partners">
                        Partneri
            </Link>
                </Menu.Item>

                <Menu.Item id="menuItem" key='/references'>
                    <Link to="/references">
                        Reference
            </Link>
                </Menu.Item>


                <Menu.Item id="menuItem" key='/about'>
                    <Link to="/about">
                        O nama
            </Link>
                </Menu.Item>
            </Menu>)
        return (
            <div id="menuContainer">
                <div id="logoMenu">
                    <a href="/">
                        <img src={Logo} alt="Logo" />
                    </a>
                </div>

                <div id="menuIcons">
                    <a target="_blank" rel="noopener noreferrer" href="mailto:facit@facit.ba">
                        <MailOutlined />
                    </a>
                    <span style={{ width: '3px' }}> </span>
                    <a style={{ marginLeft: '10%' }} target="_blank" rel="noopener noreferrer"
                        href="https://www.google.com/maps/place/Facit+d.o.o./@43.8408702,18.3325361,17z/data=!4m5!3m4!1s0x4758c929efc7f57d:0xe752e9e3a5f54102!8m2!3d43.8408818!4d18.3315383">
                        <EnvironmentOutlined />
                    </a>
                </div>

                <div id="originalMenu">{menu}</div>
                <Dropdown.Button id="newMenu" trigger="click" overlay={menu} theme="dark" icon={<MenuUnfoldOutlined />} />
            </div>
        );
    }
} export default withRouter(MyMenu);
