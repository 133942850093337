// kada se importuje 
//import {products} from './res/products';

// printer images
import XeroxPhaser3020BI from './Slike/printeri-PRINTER XEROX PHASER 3020BI.jpg';
import XeroxPhaser3020 from './Slike/printeri-XEROX PHASER 3020.jpg';
import EpsonEcoTankITSL1110 from './Slike/printeri-PRINTER EPSON ECOTANK ITS L1110.jpg';
import EpsonEcoTankITSL1300 from './Slike/printeri-PRINTER EPSON ECOTANK ITS L1300.jpg';
import EpsonEcoTankITSL1800 from './Slike/printeri-PRINTER EPSON ECOTANK ITS L1800.jpg';
import EpsonEcoTankITSL805 from './Slike/printeri-PRINTER EPSON ECOTANK ITS L805.png';
import MatricniPrinterEpsonPLQ30 from './Slike/printeri-PLQ30.jpg';
import MatricniPrinterEpsonLQ690 from './Slike/printeri-LQ 690.jpg';
import XeroxVersalinkB400 from './Slike/printeri-B400.jpg';

// multifunkcionalni uredjaji images
import XeroxWC3025NI from './Slike/multifunkcionalni-uredjaji-XEROX WC 3025NI.jpg';
import EpsonEcoTankITSL1455 from './Slike/multifunkcionalni-uredjaji-ITS L1455.jpg';
import XeroxWC3025BI from './Slike/multifunkcionalni-uredjaji-XEROX WC 3025BI.jpg';
import XeroxWorkCentre785i from './Slike/multifunkcionalni-uredjaji-XEROX WorkCentre 7835i.jpg';
import XeroxVersalinkC405 from './Slike/multifunkcionalni-uredjaji-XEROX VersaLink C405.jpg';
import XeroxVersalinkB605 from './Slike/multifunkcionalni-uredjaji-B605.jpg';
import XeroxWC3345 from './Slike/multifunkcionalni-uredjaji-XEROX WC 3345.jpg';
import XeroxWC3225 from './Slike/multifunkcionalni-uredjaji-XEROX WC 3225.jpg';
import XeroxWC3335 from './Slike/multifunkcionalni-uredjaji-XEROX WC 3335.jpg';
import XeroxWC3215 from './Slike/multifunkcionalni-uredjaji-3215.jpg';
import XeroxVersalinkC7025 from './Slike/multifunkcionalni-uredjaji-C7025.jpg';

// informaticka oprema images
// import SmartTV80cm from './Slike/informaticka-oprema-SMART TV TCL LED 80 cm (32\'\').jpg';
// import SmartTV102cm from './Slike/informaticka-oprema-SMART TV TCL LED FULL HD 102 cm (40\'\').jpg';
// import EpsonEBL510U from './Slike/informaticka-oprema-Projektor EPSON EB-L510U.jpg';
// import EpsonEB1795F from './Slike/informaticka-oprema-Projektor EPSON EB-1795F.jpg';
// import GamingStolica from './Slike/informaticka-oprema-Gaming stolica - Spirit of gamer - DEMON BLACK.jpg';
// import DronDJIMavicMini from './Slike/informaticka-oprema-DRON DJI MAVIC MINI.jpg';
// import SkenerIRISCanMouseExecutive2 from './Slike/informaticka-oprema-Skener IRIScan Mouse Executive 2.jpg';
// import EpsonEB2250U from './Slike/informaticka-oprema-Projektor EPSON EB-2250U.jpg';
// import EpsonEBL615U from './Slike/informaticka-oprema-Projektor EPSON EB-L615U.jpg';
// import PloterEpsonSureColorSCT7200 from './Slike/informaticka-oprema-Ploter EPSON SureColor SC-T7200.jpg';
// import PloterXerox510WideFormat from './Slike/informaticka-oprema-wide format.jpg';
// import TabletLenovoTabM7 from './Slike/informaticka-oprema-Tablet Lenovo Tab M7 QuaD.jpg';
// import ElektricniRomobil from './Slike/informaticka-oprema-Električni romobil UrbanGlide Ride 62S.jpg';
// import LaptopAsusX403JA from './Slike/informaticka-oprema-Asus X403JA-WB511R.jpg';
// import AutoKamera from './Slike/informaticka-oprema-autokamera.jpg';

// potrosni materijal images
import PM013R00675 from './Slike/potrosni-materijal-013R00675.jpg';
import PM013R00662 from './Slike/potrosni-materijal-013R00662.jpg';
import PM013R00602 from './Slike/potrosni-materijal-013R00602.png';
import PM013R00603 from './Slike/potrosni-materijal-013R00603.jpg';
import PM013R00684 from './Slike/potrosni-materijal-013R00684.jpg';
import PM013R00663 from './Slike/potrosni-materijal-013R00663.jpg';
import PM013R00664 from './Slike/potrosni-materijal-013R00664.jpg';
import PM113R00779 from './Slike/potrosni-materijal-113R00779.jpg';
import PM113R00780 from './Slike/potrosni-materijal-113R00780.jpg';
import PM013R00676 from './Slike/potrosni-materijal-013R00676.jpg';
import PM006R01605 from './Slike/potrosni-materijal-006R01605.jpg';
import PM106R03394 from './Slike/potrosni-materijal-106R03394.jpg';
import PMSetTonera from './Slike/potrosni-materijal-Set-tonera.jpg';
import PMSetTonera2 from './Slike/potrosni-materijal-Set-tonera2.jpg';
import PMSetTonera3 from './Slike/potrosni-materijal-Set-tonera3.jpg';
import PMSetTonera4 from './Slike/potrosni-materijal-Set-tonera4.jpg';
import PMSetTonera5 from './Slike/potrosni-materijal-Set-tonera5.jpg';
import PMSetTonera6 from './Slike/potrosni-materijal-Set-tonera6.jpg';

// mrezna oprema images
import MO1 from './Slike/mrezna-oprema-1.jpg';
import MO2 from './Slike/mrezna-oprema-2.webp';
import MO3 from './Slike/mrezna-oprema-3.jpg';
import MO4 from './Slike/mrezna-oprema-4.jpg';
import MO5 from './Slike/mrezna-oprema-5.jpg';
import MO6 from './Slike/mrezna-oprema-6.jpg';
import MO7 from './Slike/mrezna-oprema-7.jpg';
import MO8 from './Slike/mrezna-oprema-8.jpg';
import MO9 from './Slike/mrezna-oprema-9.webp';
import MO10 from './Slike/mrezna-oprema-10.png';
import MO11 from './Slike/mrezna-oprema-11.jpg';
import MO12 from './Slike/mrezna-oprema-12.jpg';
import MO13 from './Slike/mrezna-oprema-13.jpg';
import MO14 from './Slike/mrezna-oprema-14.jpg';



export const printeri  = [
    {
        id: 1,
        naziv: 'XEROX Phaser 3020BI wi/fi',
        link: 'https://www.avtera.ba/printer-xerox-phaser-3020bi/',
        cijena: 260,
        opis: 'Tehnologija printanja: laserski mono\n' +
        'Maksimalna dimenzija printa: A4\n' +
        'Namjena: kućni korisnici i mali uredi\n' +
        'Brzina printanja: 20 str\n' +
        'Povezivanje printera: USB, WiFi\n' +
        'Automatski dvostrani print: ne\n' +
        'Rezolucija printanja: 1200 x 1200\n' +
        'Maksimalni preporučeni volumen printa: 15000\n' +
        'Standardni kapacitet papira: 150 list\n' +
        'Inicijalne tinte/toner: 700 str\n' +
        'Dimenzije (Š x V x D): 331 x 188 x 215 mm\n' +
        'Težina: 4,1 kg\n' +
        'Garancija (mj): 12 mjeseci',
        slika: XeroxPhaser3020BI
    },
    {
        id: 2,
        naziv: 'XEROX Phaser 3020',
        link: '',
        cijena: 140,
        opis: 'Tip: laser\n' +
        'Brzina ispisa: - C/B: 20str/min\n' +
        'Rezolucija ispisa: do 1200 x 1200dpi\n' +
        'Vrijeme ispisa prve stranice: 8.5s\n' +
        'Memorija: 128MB\n' +
        'Procesor: 600MHz\n' +
        'Ladica za ulaz: stranica\n' +
        'Pretinac za izlaz: stranica\n' +
        'Mjesečni ciklus: 15000 stranica\n' +
        'Apple AirPrint, Google Cloud Print\n' +
        'Toner: 106R02773, 106R03048\n' +
        'Sučelje: USB 2.0, WiFi\n' +
        'Dimenzije: 331 x 188 x 215mm\n' +
        'Težina: 4.1kg',
        slika: XeroxPhaser3020
      },
      {
        id: 3,
        naziv: 'EPSON EcoTank ITS L1110',
        link: 'https://www.avtera.ba/printer-epson-ecotank-its-l1110/',
        cijena: 385,
        opis: 'Tehnologija printanja: inkjet kolor (sa inktank spremnicima)\n' +
        'Maksimalna dimenzija printa: A4\n' +
        'Namjena: kućni korisnici i mali uredi\n' +
        'Brzina printanja: ISO/IEC 24734: 10 Stranica/min Monokrom, 5 Stranica/min Colour\n' +
        'Povezivanje printera: USB\n' +
        'Automatski dvostrani print: ne\n' +
        'Rezolucija printanja: do 5760 x 1440 dpi\n' +
        'Maksimalni preporučeni volumen printa: do 500 stranica\n' +
        'Standardni kapacitet papira: 100 listova\n' +
        'Inicijalne tinte/toner: Bk za 8.100 strana i CMY za 6.500 strana\n' +
        'Dimenzije (Š x V x D): 375‎ x 169 x 347 mm\n' +
        'Težina: 2,7 kg\n' +
        'Garancija (mj): 12 + (besplatno produženje na 36 mjeseci uz registraciju na web stranici proizvođača)',
        slika: EpsonEcoTankITSL1110,
      },
      {
        id: 4,
        naziv: 'EPSON EcoTank ITS L1300',
        link: 'https://www.avtera.ba/printer-epson-ecotank-its-l1300-a3plus/',
        cijena: 1055,
        opis: 'Tehnologija printanja: inkjet kolor (sa inktank spremnicima)\n' +
        'Maksimalna dimenzija printa: A3+\n' +
        'Namjena: poslovna upotreba\n' +
        'Brzina printanja: ISO/IEC 24734: 15 Stranica/min Monokrom, 5,5 Stranica/min Colour\n' +
        'Povezivanje printera: USB\n' +
        'Automatski dvostrani print: ne\n' +
        'Rezolucija printanja: do 5760 x 1440 dpi\n' +
        'Maksimalni preporučeni volumen printa: 100 stranica dnevno\n' +
        'Standardni kapacitet papira: 100 listiova, 20 listova foto papira\n' +
        'Inicijalne tinte/toner: Bk za 7.100 strana i CMY za 5.700 strana\n' +
        'Dimenzije (Š x V x D): 705‎ x 215 x 322 mm\n' +
        'Težina: 12,2 kg\n' +
        'Papir težine: 64 g/m² - 300 g/m²\n' +
        'Garancija (mj): 12 + (besplatno produženje na 36 mjeseci uz registraciju na web stranici proizvođača)',
        slika: EpsonEcoTankITSL1300
      },
      {
        id: 5,
        naziv: 'EPSON EcoTank ITS L1800',
        link: 'https://www.avtera.ba/printer-epson-ecotank-its-l1800-a3plusfoto/',
        cijena: 1340,
        opis: 'Tehnologija printanja: inkjet kolor (sa inktank spremnicima)\n' +
        'Maksimalna dimenzija printa: A3+\n' +
        'Namjena: kućni korisnici i mali uredi Foto printer\n' +
        'Brzina printanja: ISO/IEC 24734: 2,6 Stranica/min Monokrom, 2,6 Stranica/min Colour\n' +
        'Povezivanje printera: USB\n' +
        'Automatski dvostrani print: ne\n' +
        'Rezolucija printanja: do 5760 x 1440 dpi\n' +
        'Maksimalni preporučeni volumen printa: 100 stranica dnevno\n' +
        'Standardni kapacitet papira: 100 listiova, 20 listova foto papira\n' +
        'Inicijalne tinte/toner: BK, CMY, LC i LM za 1500 fotografija (10 x 15)\n' +
        'Dimenzije (Š x V x D): 705‎ x 215 x 322 mm\n' +
        'Težina: 12,5 kg\n' +
        'Papir težine: 64 g/m² - 320 g/m²\n' +
        'Garancija (mj): 12 + (besplatno produženje na 36 mjeseci uz registraciju na web stranici proizvođača)',
        slika: EpsonEcoTankITSL1800
      },
      {
        id: 6,
        naziv: 'EPSON EcoTank ITS L805',
        link: 'https://www.avtera.ba/printer-epson-ecotank-its-l805/',
        cijena: 690,
        opis: 'Tehnologija printanja: inkjet kolor (sa inktank spremnicima)\n' +
        'Maksimalna dimenzija printa: A4\n' +
        'Namjena: kućni korisnici i mali uredi Foto i CD/DVD printer\n' +
        'Brzina printanja: ISO/IEC 24734: 5,1 Stranica/min Monokrom, 5 Stranica/min Colour\n' +
        'Povezivanje printera: USB, WiFi\n' +
        'Automatski dvostrani print: ne\n' +
        'Rezolucija printanja: do 5760 x 1440 dpi\n' +
        'Maksimalni preporučeni volumen printa: 100 stranica dnevno\n' +
        'Standardni kapacitet papira: 120 listova, 20 fotografskih papira A4\n' +
        'Inicijalne tinte/toner: BK, CMY, LC i LM za 1800 fotografija (10 x 15)\n' +
        'Dimenzije (Š x V x D): 547‎ x 187 x 289 mm\n' +
        'Težina: 6 kg\n' +
        'Garancija (mj): 12 + (besplatno produženje na 36 mjeseci uz registraciju na web stranici proizvođača)',
        slika: EpsonEcoTankITSL805
      },
      {
        id: 7,
        naziv: 'Matrični Printer EPSON PLQ-30',
        link: '',
        cijena: 1170,
        opis: 'Maksimalna dimenzija printa: A4\n' +
        'Broj iglica: 24\n' +
        'Povezivanje: USB , Serijsko i Paralelno\n' +
        'Ispis više kopija: 1 original + 6 kopije\n' +
        'Brzina printanja: HighSpeed-Draft: 585 cpi: 10 Znakova/s, 520 cpi\n' +
        'Papir: Pojedinačni list naprijed, Izlaz papira naprijed i straga, Mjerenje debljine papira: da\n' +
        'Beskrajan papir: ne\n' +
        'Tabela znakova: PC437, PC850, PC858, PC864, ISO-8859-1, ISO-8859-15\n' +
        'Dimenzije (Š x D x V): 384‎ x 203 x 280 mm\n' +
        'Težina: 8,00 kg\n' +
        'Srednje vrijeme između kvarova: 12 000 sati\n' + 
        'Vijek trajanja glave: 400 miliona udaraca po žici\n' +
        'Nivo buke: 55dBA\n' +
        'Garancija (mj): 12',
        slika: MatricniPrinterEpsonPLQ30
      },
      {
        id: 8,
        naziv: 'Matrični Printer EPSON LQ-690',
        link: '',
        cijena: 1300,
        opis: 'Maksimalna dimenzija printa: A4\n' +
        'Broj iglica: 24\n' +
        'Povezivanje: USB i Paralelno\n' +
        'Ispis više kopija: 1 original + 6 kopije\n' +
        'Brzina printanja: HighSpeed-Draft: 10 cpi: 440 Znakova/s, 12 cpi: 529 Znakova/s\n' +
        'Papir: Pojedinačni list naprijed, Beskrajni papir straga, Izlaz papira naprijed\n' +
        'Beskrajan papir: da\n' +
        'Tabela znakova: PC437, PC708, PC720, PC850, PC858, PC864, PCAR864, ISO-8859-15, Roman 8, Italic\n' +
        'Dimenzije (Š x D x V): 480‎ x 210 x 370 mm\n' +
        'Težina: 6,8 kg\n' +
        'Srednje vrijeme između kvarova: 20 000 sati\n' + 
        'Vijek trajanja glave: 400 miliona udaraca po žici\n' +
        'Garancija (mj): 12',
        slika: MatricniPrinterEpsonLQ690
      },
      {
        id: 9,
        naziv: 'XEROX VersaLink B400',
        link: '',
        cijena: 955,
        opis: 'Tehnologija printanja: laserski mono\n' +
        'Maksimalna dimenzija printa: A4\n' +
        'Namjena: poslovna upotreba\n' +
        'Brzina printanja: 45 str\n' +
        'Povezivanje printera: USB, mreža , USB 3.0, NFC\n' +
        'Automatski dvostrani print: da\n' +
        'Rezolucija printanja: 1200 x 1200\n' +
        'Maksimalni preporučeni volumen printa: 110000\n' +
        'Standardni kapacitet papira: 550 list\n' +
        'Inicijalne tinte/toner: 5900 str\n' +
        'Dimenzije (Š x V x D): 459 x 339 x 426 mm\n' +
        'Težina: 14 kg',
        slika: XeroxVersalinkB400
      },
];

export const multifunkcionalniUredjaji = [
    {
        id: 1,
        naziv: 'XEROX WC 3025NI',
        cijena: 340,
        opis: 'Tehnologija printanja: laserski mono\n' +
        'Maksimalna dimenzija printa: A4\n' +
        'Namjena: kućni korisnici i mali uredi\n' +
        'Brzina printanja: 20 str/min\n' +
        'Povezivanje printera: USB, mreža, WiFi\n' +
        'Automatski dvostrani print: ne\n' +
        'Rezolucija printanja: 1200 x 1200 dpi\n' +
        'Maksimalni preporučeni volumen printa: 15000\n' +
        'Faks: da\n' +
        'ADF: da\n' +
        'Obostrano skeniranje: ne\n' +
        'Standardni kapacitet papira: 100 listova\n' +
        'Inicijalne Tinte/Toner: 700 str' +
        'Dimenzije (Š x D x V): 406 x 309 x 360 mm\n' +
        'Težina: 8,6 kg',
        slika: XeroxWC3025NI
    },
    {
        id: 2,
        naziv: 'MFP EPSON EcoTank ITS L1455',
        cijena: 1975,
        opis: 'Tehnologija printanja: inkjet kolor (sa inktank spremnicima)\n' +
        'Maksimalna dimenzija printa: A3+\n' +
        'Namjena: kućni korisnici i mali uredi\n' +
        'Brzina printanja: ISO/IEC 24734: 18 Stranica/min Monokrom, 10 Stranica/min Colour\n' +
        'Povezivanje printera: WiFi direkt WiFi , USB, Ethernet\n' +
        'Automatski dvostrani print: da\n' +
        'Rezolucija printanja: do 4800 x 2400 dpi\n' +
        'Maksimalni preporučeni volumen printa: 500 dnevno\n' +
        'Faks: da\n' +
        'ADF: da\n' +
        'Obostrano skeniranje: da\n' +
        'Standardni kapacitet papira: 500 listova\n' +
        'Inicijalne tinte/toner: Bk za 10.500 strana i CMY za 10.500 strana\n' +
        'Dimenzije (Š x V x D): 795‎ x 560 x 640 mm\n' +
        'Težina: 23,16 kg\n' +
        'Papir: težine 64 g/m² - 250 g/m²\n' +
        'Garancija (mj): 12 + (besplatno produženje na 36 mjeseci uz registraciju na web stranici proizvođača)',
        slika: EpsonEcoTankITSL1455
    },
    {
        id: 3,
        naziv: 'XEROX WC 3025BI',
        cijena: 260,
        opis: 'Tehnologija printanja: laserski mono\n' +
        'Maksimalna dimenzija printa: A4\n' +
        'Namjena: kućni korisnici i mali uredi\n' +
        'Brzina printanja: 20 str\n' +
        'Povezivanje printera: USB, WiFi\n' +
        'Automatski dvostrani print: ne\n' +
        'Rezolucija printanja: 1200 x 1200\n' +
        'Maksimalni preporučeni volumen printa: 15000 str\n' +
        'Faks: ne\n' +
        'ADF: ne\n' +
        'Obostrano skeniranje: ne\n' +
        'Standardni kapacitet papira: 100 list\n' +
        'Inicijalne tinte/toner: 700 str\n' +
        'Dimenzije (Š x V x D): 406 x 257 x 360 mm\n' +
        'Težina: 8,6 kg',
        slika: XeroxWC3025BI
    },
    {
        id: 4,
        naziv: 'XEROX WorkCentre 7835i',
        cijena: 2500,
        opis: 'NAPOMENA: Uređaj pored finišera, dolazi i sa setom originalnih tonera i setom originalnih drumova\n' +
        'BRZINA: do 30/min boja i cb / do 35/min\n' +
        'FUNKCIJA: copy/print/scan/email, opcija fax\n' +
        'REZOLUCIJA: do 1200 x 2400 dpi\n' +
        'KAPACITET PAPIRA: DADF/110 listova, Bypass/100, Ladice/520 lisotva\n' +
        'DVOSTRANI ISPIS: standard\n' +
        'PROCESOR: Dual-core 1.23 GHz\n' +
        'MEMORIJA: 2 GB system plus 1 GB page memory\n' +
        'HARD DISK: 250 GB (min)\n' +
        'POVEZIVOST: 10/100/1000 BaseT Ethernet, High-Speed USB 2.0 direct print (opcija: Wi-Fi (Xerox® USB Wireless Adapter))\n' +
        'JEZICI PODRŠKE: Adobe PostScript® 3™, PCL® 5c, PCL® 6, PDF (opcija: XPS)\n' +
        'OPERATIVNI SISTEMI: Mac OS 10.10, Mac OS 10.11, Mac OS 10.8, Mac OS 10.9, Redhat® Fedora® Core 20 x86, Ubuntu® 14.04 x64, Windows Server 2012 R2 (32- and 64-bit), Windows® 10 (32- and 64-bit), Windows® 2003 Server SP2 R2 (32- and 64-bit), Windows® 2008 Server R2 (32- and 64-bit), Windows® 7 (32- and 64-bit), Windows® 8 (32- and 64-bit), Windows® 8.1 (32- and 64-bit), Windows® Vista SP2 (32- and 64-bit), openSUSE® 13.1 x64',
        slika: XeroxWorkCentre785i
    },
    {
        id: 5,
        naziv: 'XEROX VersaLink C405V_DN',
        cijena: 2500,
        opis: 'MODEL:C405V_DN\n' +
        'ISPIS: kolor\n' +
        'VELIČINA PAPIRA:A4\n' +
        'BRZINA PRINTANJA:c/b do 35 stranica u minuti, kolor do 35 stranica u minuti\n' +
        'DVOSTRANI ISPIS: da\n' +
        'PREPORUČENI MJESEČNI VOLUMEN ISPISA: do 8500 stranica mjesečno\n' +
        'POVEZIVOST: USB, Ethernet, opcija Wi-Fi\n' +
        'OPERATIVNI SISTEMI: Windows, Mac OS 10.5+, Linux ...\n' +
        'POTROŠNI:\n' +
        'TONER:\n' +
        'BLACK: Standard (106R03508), high (106R03520), extra high (106R03532)\n' +
        'CYAN: Standard (106R03510), high (106R03522), extra high (106R03534)\n' +
        'MAGENTA: Standard (106R03511), high (106R03523), extra high (106R03535)\n' +
        'YELLOW: Standard (106R03509), high (106R03521), extra high (106R03533)\n' +
        'IMAGING UNIT: 60.000 otisaka (108R01121)\n' +
        'WASTE CONTAINER: 30.000 otisaka (108R01124)',
        slika: XeroxVersalinkC405
    },
    {
        id: 6,
        naziv: 'XEROX VersaLink B605',
        cijena: 3090,
        opis: 'Tehnologija printanja: laserski mono\n' +
        'Maksimalna dimenzija printa: A4\n' +
        'Namjena: poslovna upotreba\n' +
        'Brzina printanja: 55 str\n' +
        'Povezivanje printera: USB, mreža , NFC\n' +
        'Automatski dvostrani print: da\n' +
        'Rezolucija printanja: 1200 x 1200\n' +
        'Maksimalni preporučeni volumen printa: 250000 str\n' +
        'Faks: da\n' +
        'Obostrano skeniranje: da\n' +
        'Standardni kapacitet papira: 550 list\n' +
        'Inicijalne tinte/toner: za 10300 str\n' +
        'Dimenzije (Š x V x D): 470 x 643,7 x 502,8 mm\n' +
        'Težina: 30,5 kg\n' +
        'Garancija (mj): 12 mjeseci',
        slika: XeroxVersalinkB605
    },
    {
        id: 7,
        naziv: 'XEROX WC 3345',
        cijena: 820,
        opis: 'Tehnologija printanja: laserski mono\n' +
        'Maksimalna dimenzija printa: A4\n' +
        'Namjena: poslovna upotreba\n' +
        'Brzina printanja: 42 str\n' +
        'Povezivanje printera: USB, mreža, WiFi\n' +
        'Automatski dvostrani print: da\n' +
        'Rezolucija printanja: 1200 x 1200\n' +
        'Maksimalni preporučeni volumen printa: 80000\n' +
        'Faks: da\n' +
        'ADF: da\n' +
        'Obostrano skeniranje da (RADF): 50 list\n' +
        'Standardni kapacitet papira: 250 list\n' +
        'Inicijalne tinte/toner: 2600 str\n' +
        'Dimenzije (Š x V x D): 469 x 482,6 x 444,3 mm\n' +
        'Težina: 15,51 kg',
        slika: XeroxWC3345
    },
    {
        id: 8,
        naziv: 'XEROX WC 3225',
        cijena: 510,
        opis: 'Tehnologija printanja: laserski mono\n' +
        'Maksimalna dimenzija printa: A4\n' +
        'Namjena: kućni korisnici i mali uredi\n' +
        'Brzina printanja: 28str\n' +
        'Povezivanje printera: USB, mreža, WiFi\n' +
        'Automatski dvostrani print: da\n' +
        'Rezolucija printanja: 4800 x 600\n' +
        'Maksimalni preporučeni volumen printa: 30000\n' +
        'Faks: da\n' +
        'ADF: da\n' +
        'Obostrano skeniranje: ne\n' +
        'Standardni kapacitet papira: 250 list\n' +
        'Inicijalne tinte/toner: 1500 str\n' +
        'Dimenzije (Š x V x D): 401 x 365 x 362 mm\n' +
        'Težina: 11,4 kg',
        slika: XeroxWC3225
    },
    {
        id: 9,
        naziv: 'XEROX WC 3335',
        cijena: 700,
        opis: 'Tehnologija printanja: laserski mono\n' +
        'Maksimalna dimenzija printa: A4\n' +
        'Namjena: poslovna upotreba\n' +
        'Brzina printanja: 33 str\n' +
        'Povezivanje printera: USB, mreža, WiFi\n' +
        'Automatski dvostrani print: da\n' +
        'Rezolucija printanja: 1200 x 1200\n' +
        'Maksimalni preporučeni volumen printa: 50000\n' +
        'Faks: da\n' +
        'ADF: da\n' +
        'Obostrano skeniranje: ne\n' +
        'Standardni kapacitet papira: 250 list\n' +
        'Inicijalne tinte/toner: 2600 str\n' +
        'Dimenzije (Š x V x D): 414,6 x 420,6 x 449 mm\n' +
        'Težina: 15,51 kg',
        slika: XeroxWC3335
    },
    {
        id: 10,
        naziv: 'XEROX WC 3215',
        cijena: 410,
        opis: 'Tehnologija printanja: laserski mono\n' +
        'Maksimalna dimenzija printa: A4\n' +
        'Namjena: kućni korisnici i mali uredi\n' +
        'Brzina printanja: 26 str\n' +
        'Povezivanje printera: USB, mreža, WiFi\n' +
        'Automatski dvostrani print: ne\n' +
        'Rezolucija printanja: 1200 x 1200\n' +
        'Maksimalni preporučeni volumen printa: 30000\n' +
        'Faks: da\n' +
        'ADF: da\n' +
        'Obostrano skeniranje: ne\n' +
        'Standardni kapacitet papira: 250 list\n' +
        'Inicijalne tinte/toner: 1500 toner\n' +
        'Dimenzije (Š x V x D): 401 x 365 x 362 mm\n' +
        'Težina: 11,2',
        slika: XeroxWC3215
    },
    {
        id: 11,
        naziv: 'XEROX VersaLink C7025',
        cijena: 3480,
        opis: 'Funkcije: printanje/kopiranje/skeniranje\n' +
        'Format i tehnologija: A3, laserski\n' +
        'Rezolucija ispisa: do 1.200 x 2.400 dpi\n' +
        'Brzina printanja: do 25 stranica/min\n' +
        'Povezivost: mreža, USB, NFC \n' +
        'Garancija: 1 godina\n' +
        'Toner: 106R03737/38/89/40/41/42/43/44\n' +
        'U prilogu: za 16.100 stranica (crna) i 9.800 stranica (u boji)',
        slika: XeroxVersalinkC7025
    },
];

export const informatickaOprema = [
    // {
    //     id: 1,
    //     naziv: 'SMART TV TCL LED 80 cm (32\'\')',
    //     cijena: 400,
    //     opis: "Dijagonala zaslona: 80 cm (32'')\n" + 
    //     'Rezolucija zaslona: 1366 x 768 HD Ready\n' +
    //     'Vrsta zaslona: LED\n' +
    //     'Smart TV (operativni sistem): AndroidTV & TV+\n' +
    //     'Osvježavanje zaslona: 60Hz\n' +
    //     'HDR: HDR\n' +
    //     'Dodatne TV osobine: Micro Dimming\n' +
    //     'Kvaliteta slike: 300PPI\n' +
    //     'Sistem zvučnika: 2x5 W, Dolby Digital Plus\n' +
    //     'Zvuk: Down Firing\n' +
    //     'Procesor: Quad Core\n' +
    //     'Povezivanje: WiFi, LAN, DLNA, WiFi Direct\n' +
    //     'Priključci: 1xUSB 2.0, 1xRJ45, 2xHDMI 1.4 (ARC, CEC), 1x Satellite In (Main, F-type female), 1x S/PDIF, 1x CI+, 1x Antenna (RF) (fem ale), 1xCl+, 1x AV component In (YPbPr+L/R), 1x AV Composite In (Video+L/R)\n' +
    //     'Daljinski upravljač: DA, RC802N1\n' +
    //     'Težina: 3,90 kg\n' +
    //     'Dimenzije (Š x D x V): 732x175x480mm\n' +
    //     'Mjere bez stalka: 732x435x11-40-80mm\n' +
    //     'Energetski razred: A+\n' +
    //     'Boja: Crna\n' +
    //     'VESA standard: 100x100mm\n' +
    //     'Dodatne informacije: RoHS Certificate\n' +
    //     'Garancija (mj): 24+36',
    //     slika: SmartTV80cm
    // },
    // {
    //     id: 2,
    //     naziv: 'SMART TV TCL LED FULL HD 102 cm (40\'\')',
    //     cijena: 530,
    //     opis: 'Dijagonala zaslona: 102 cm (40\'\')\n' + 
    //     'Rezolucija zaslona: 1920 x 1080 Full HD\n' +
    //     'Vrsta zaslona: LED\n' +
    //     'Smart TV (operativni sistem): Android\n' +
    //     'Osvježavanje zaslona: 60Hz\n' +
    //     'HDR: HDR\n' +
    //     'Dodatne TV osobine: Micro Dimming\n' +
    //     'Kvaliteta slike: 400PPI\n' +
    //     'Sistem zvučnika: 2x8 W, Dolby Digital Plus\n' +
    //     'Zvuk: Full Range, Down Firing\n' +
    //     'Procesor: Quad Core\n' +
    //     'Povezivanje: LAN, WiFi, DLNA, WiFi Direct\n' +
    //     'Priključci: 1xUSB 2.0, 1x RJ45, 2x HDMI 1.4 (ARC, CEC), 1x Satellite In (Main, F-type female), 1x AV Composite In (Video+ L/R), 1xS/ PDIF, 1x AV Component In (YPbPr+ L/R), 1x 3.5mm Audio Out, 1x CI+, 1x Antenna (RF) (female)\n' +
    //     'Daljinski upravljač: DA, RC802N1\n' +
    //     'Težina: 6,40 kg\n' +
    //     'Dimenzije (Š x D x V): 905x186x575 mm\n' +
    //     'Mjere bez stalka: 905x524x 11-40-75 mm\n' +
    //     'Energetski razred: A+\n' +
    //     'Boja: Crna\n' +
    //     'VESA standard: 100x100mm\n' +
    //     'Dodatne funkcionalnosti: Color Extender, HDCP, Mega Dinamic Contrast Picture Noise Reduction, Pure Image Ultra' +
    //     'Dodatne informacije: RoHS Certificate\n' +
    //     'Garancija (mj): 24+36',
    //     slika: SmartTV102cm
    // },
    // {
    //     id: 3,
    //     naziv: 'Projektor EPSON EB-L510U',
    //     cijena: 5490,
    //     opis: 'Vrsta projektora: Instalacijski\n' + 
    //     'Osvjetljenje: 5000 Lumena\n' +
    //     'Tehnologija: Laser 3LCD\n' +
    //     'Rezolucija: FHD, 1920 x 1200\n' +
    //     'Povezivanje (pored HDMI): Ethernet\n' +
    //     'Priključci: USB 2.0 tipa A, USB 2.0 tipa B, RS-232C, Ethernet sučelje, VGA ulaz (2x), VGA izlaz, HDMI ulaz (2x), HDBaseT, Stereo miniutičnica za audioizlaz, Stereo miniutičnica za audioulaz (2x), Bežični LAN IEEE 802.11b/g/n (Opcija)\n' +
    //     'Kontrast: 2.500.000 : 1\n' +
    //     'Format projekcije: 16:10\n' +
    //     'Glasnost: 37 dB (A)\n' +
    //     'Trajanje lampe: 20.000 Sati , 30.000 Sati Eco\n' +
    //     'Zvučnici: 10W\n' +
    //     'Bežično povezivanje: Opciono\n' +
    //     'Boja kućišta: Bijela\n' +
    //     'Dimenzije (Š x D x V): 440‎ x 304 x 120 mm\n' +
    //     'Težina: 8,5kg\n' +
    //     'Dodatne informacije: Automatsko uključivanje, Automatski odabir ulaza, Ugrađeni zvučnik, Prilagodljivi korisnički logotip, Preglednik za JPEG-ove, Dugi vijek trajanja izvora svjetlosti\n' +
    //     'Garancija: 60 mjeseci ili 12 000 sati',
    //     slika: EpsonEBL510U
    // },
    // {
    //     id: 4,
    //     naziv: 'Projektor EPSON EB-1795F',
    //     cijena: 2350,
    //     opis: 'Vrsta projektora: Poslovni Ultramobilni projektor\n' + 
    //     'Osvjetljenje: 3200 Lumena\n' +
    //     'Tehnologija: 3LCD\n' +
    //     'Rezolucija: FHD, 1920 x 1200\n' +
    //     'Povezivanje (pored HDMI): wireless Miracast, NFC\n' +
    //     'Priključci: USB 2.0 tipa A, USB 2.0 tipa B, VGA ulaz, HDMI ulaz, Kompozitni ulaz, Stereo miniutičnica za audioulaz, Miracast, MHL, Near Field Communication (NFC), Bežični LAN IEEE 802.11b/g/n\n' +
    //     'Kontrast: 10.000 : 1\n' +
    //     'Format projekcije: 16:9\n' +
    //     'Glasnost: 39 dB (A)\n' +
    //     'Trajanje lampe: 4.000 h, 7.000 h u štednom načinu rada\n' +
    //     'Zvučnici: 1W\n' +
    //     'Bežično povezivanje: da\n' +
    //     'Boja kućišta: Bijela\n' +
    //     'Dimenzije (Š x D x V): 292‎ x 213 x 44 mm\n' +
    //     'Težina: 1,8kg\n' +
    //     'Dodatne informacije: PC Free projekcija, Priložena torba\n' +
    //     'Garancija: 60 mjeseci Garancija na lampu 60 mjeseci ili 1000 sati',
    //     slika: EpsonEB1795F
    // },
    // {
    //     id: 5,
    //     naziv: 'Gaming stolica - Spirit of gamer - DEMON BLACK',
    //     cijena: 500,
    //     opis: 'Boja stolice: Crna\n' +
    //     'Osnovne informacije: Visokokvalitetna PVC koža, uključuje 2 jastuka za vrat i lumbalno, podesive naslone za ruke s 2D visine\n' +
    //     'Dodatne informacije: U paketu su 2 jastuka za vrat i lumbalni dio5 gumirani kotači rotirajućih 360 stupnjeva, nosivost do 120 kg\n' +
    //     'Težina: 19 kg\n' +
    //     'Dimenzije (Š x D x V): 51 cm x 132 cm x 70 cm\n' +
    //     'Garancija (mj): 24',
    //     slika: GamingStolica
    // },
    // {
    //     id: 6,
    //     naziv: 'DRON DJI MAVIC MINI',
    //     cijena: 775,
    //     opis: 'Svrha upotrebe: osnovni\n' +
    //     'Rezolucija kamere: 12 M\n' +
    //     'Najveća rezolucija videa: Full HD 1920×1080 Full HD 1920×1080 FHD: 1920×1080 25/30/50/60 p, 2.7 K: 2720×1530 25/30 p\n' +
    //     'Nosač kamere: 3 osni\n' +
    //     'Sistem za izbjegavanje prepreka: da, 1 pravac\n' +
    //     'Domet upravljanja: do 500 m\n' +
    //     'Autonomija leta: 30 min (mjereno dok leti brzinom od 14 km / h u uvjetima bez vjetra)\n' +
    //     'Težina drona: do 500 g 249 g.\n' +
    //     'Navigacijski sistem: GPS+GLONASS\n' +
    //     'Način upravljanja: Daljinski upravljač, nosač daljinskog upravljača do 160 mm širine i debljne 6,5-8,5mm\n' +
    //     'Najveća brzina (bez vjetra): 13 m / s (mod S), 8 m / s (mod P), 4 m / s (mod C)\n' +
    //     'Maksimalna brzina uspona / spuštanja uspon: 4 m / s (mod S), 2 m / s (mod P), 1,5 m / s (mod C) silazak: 3 m / s (mod S), 1,8 m / s (mod P) ), 1 m\n' +
    //     'Memorijska kartica: micro SD (nije priložena), podržano do 256 GB\n' +
    //     'Dodatne informacije: Dimenzije: složen:140x82x57mm, rasklopljen:160x202x55mm, rasklopljen (s propelerima) 245x290x55mm\n' +
    //     'Garancija (mj): 24m',
    //     slika: DronDJIMavicMini
    // },
    // {
    //     id: 7,
    //     naziv: 'Skener IRIScan Mouse Executive 2',
    //     cijena: 215,
    //     opis: 'Povezivanje: USB\n' +
    //     'Rezolucija: 400 dpi\n' +
    //     'Garancija (mj): 12 mjeseci',
    //     slika: SkenerIRISCanMouseExecutive2
    // },
    // {
    //     id: 8,
    //     naziv: 'Projektor EPSON EB-2250U ',
    //     cijena: 3280,
    //     opis: 'Vrsta projektora: Poslovni\n' +
    //     'Osvjetljenje: 5000 Lumena\n' +
    //     'Tehnologija: 3LCD\n' +
    //     'Rezolucija: FHD, 1920 x 1200\n' +
    //     'Povezivanje (pored HDMI): Ethernet\n' +
    //     'Priključci: USB 2.0 tipa A, USB 2.0 tipa B, RS-232C, Ethernet sučelje (100 Base-TX/10 Base-T), VGA ulaz (2x), VGA izlaz, HDMI ulaz (2x), Kompozitni ulaz, RGB ulaz (2x), RGB izlaz, MHL, Stereo miniutičnica za audioizlaz\n' +
    //     'Kontrast: 15.000 : 1\n' +
    //     'Format projekcije: 16:10\n' +
    //     'Glasnost: 39 dB (A)\n' +
    //     'Trajanje lampe: 5.000 h, 10.000 h u štednom načinu rada\n' +
    //     'Zvučnici: 16W\n' +
    //     'Bežično povezivanje: Opciono\n' +
    //     'Boja kućišta: Bijela\n' +
    //     'Dimenzije (Š x D x V): 377‎ x 291 x 110 mm\n' +
    //     'Težina: 4,6kg\n' +
    //     'Dodatne informacije: Klizač za isključivanje slike i tona, Automatsko uključivanje, Automatski odabir ulaza, Auto ispravljanje iskrivljenja, Prezentacija gestama, Mrežna administracija, OSD funkcija kopiranja, PC Free projekcija\n' +
    //     'Garancija (mj): 60 mjeseci; Garancija na lampu 60 mjeseci ili 1000 sati',
    //     slika: EpsonEB2250U
    // },
    // {
    //     id: 9,
    //     naziv: 'Projektor EPSON EB-L615U',
    //     cijena: 6490,
    //     opis: 'Vrsta projektora: Instalacijski\n' +
    //     'Osvjetljenje: 6000 Lumena\n' +
    //     'Tehnologija: Laser 3LCD\n' +
    //     'Rezolucija: FHD, 1920 x 1200\n' +
    //     'Povezivanje (pored HDMI): wireless\n' +
    //     'Priključci: USB 2.0 tipa A, USB 2.0 tipa B, RS-232C, Ethernet sučelje, VGA ulaz (2x), VGA izlaz, HDMI ulaz (2x), HDBaseT, Stereo miniutičnica za audioizlaz, Stereo miniutičnica za audioulaz (2x), Bežični LAN IEEE 802.11b/g/n\n' +
    //     'Kontrast: 2.500.000 : 1\n' +
    //     'Format projekcije: 16:10\n' +
    //     'Glasnost: 37 dB (A)\n' +
    //     'Trajanje lampe: 20.000 h, 30.000 h u štednom načinu rada\n' +
    //     'Zvučnici: 10W\n' +
    //     'Bežično povezivanje: WiFi integrisan\n' +
    //     'Boja kućišta: Crna\n' +
    //     'Dimenzije (Š x D x V): 440‎ x 304 x 120 mm\n' +
    //     'Težina: 8,5kg\n' +
    //     'Dodatne informacije: Automatsko uključivanje, Automatski odabir ulaza, Ugrađeni zvučnik, Prilagodljivi korisnički logotip, Preglednik za JPEG-ove, Dugi vijek trajanja izvora svjetlosti\n' +
    //     'Garancija (mj): 60 mjeseci ili 12 000 sati',
    //     slika: EpsonEBL615U
    // },
    // {
    //     id: 10,
    //     naziv: 'Ploter EPSON SureColor SC-T7200',
    //     cijena: 4800,
    //     opis: 'Vrsta printera: printer\n' +
    //     'Namjena: GIS/CAD\n' +
    //     'Maksimalna dimenzija printa: B0 , 44" = B0 = 1.117,6 mm\n' +
    //     'Povezivanje: Mreža i USB 2.0\n' +
    //     'Rezolucija printanja: 2.880 x 1.440 dpi\n' +
    //     'Brzina printanja: Monokrom 28 Sekunde, Colour 28 Sekunde\n' +
    //     'Broj kertridža: 5 Matte Black, Photo Black, Cyan, Magenta, Yellow\n' +
    //     'Postolje: Priloženo\n' +
    //     'Inicijalne tinte/toner: Priložene inicijalne tinte dovoljne za punjenje sistema, te print nekoliko desetina kvadrata\n' +
    //     'Dimenzije (Š x V x D): 1.608‎ x 813 x 1.128 mm\n' +
    //     'Težina: 92kg\n' +
    //     'Debljine papira: 0,08 mm - 1,5 mm\nMemorija: 1GB\nGlava plotera je doživotna i ne mijenja se kao potrošni materijal\n' +
    //     'Garancija (mj): 12',
    //     slika: PloterEpsonSureColorSCT7200
    // },
    // {
    //     id: 11,
    //     naziv: 'PLOTER Xerox 510 Wide Format',
    //     cijena: 1700,
    //     opis: '(Postoji mogućnost rente)\n' +
    //     'Brzina: 4,5 ips -Rozlučenje: 400 x 400 dpi\n' +
    //     '-Prepusni rezultat: 5, 7 ili 10 D (A1) ispisuje u minuti\n' +
    //     'Tehnologija: PDLs FreeFlow Accxes kontrolerom: HP-GL2, FileNET, Xerox Emulacija 150, Standardi podataka Versatec, CalComp, TIFF 6.0, NIRS, CALS 1 i 2, RLE, CGM, VCGL, Opcionalno: Adobe PostScript 3, PDF-mrežna sučelja: IEEE 1284, dvosmjerna centronics, SCSI-2, 10/100 Ethernet ( TCP / IP) podržava 10/100 BaseT, neobavezni eksterni poslužitelj ispisa\n' +
    //     '-CPU: Power PC -Proces / Imaging: Xerographic\n' +
    //     'LED-Memorija: 256 MB standard, proširiv na 512 MB\n' +
    //     '-Hard Disk: 80 GB IDE Rukovanje papirom: -Paper izvori : 1 do 3 rolne\n' +
    //     '-Standarni kapacitet papira: do 650 stopa\n' +
    //     '-Media Dimenzije - Minimalno: 8,5 x 11 inča\n' +
    //     '-Media Dimenzije Maksimalno: Ispis: 36x 82 \'; Kopija: 36 x 100 \'-Media Vrste: Vez, vellum, film, tračevi papir Ulaz: Ostali uređaji za unos\n' +
    //     '-Izlazni sustav za skeniranje širokog formata: -Stacker Flat Stacker: do 100 otisaka Stacker 1000 naslaga do 1.000 otisaka miješanih veličina',
    //     slika: PloterXerox510WideFormat
    // },
    // {
    //     id: 12,
    //     naziv: 'Tablet Lenovo Tab M7 QuadC/1GB/16GB/WiFi/7"/crni',
    //     cijena: 244,
    //     opis: 'Dijagonala zaslona: 17,78 cm (7")\n' +
    //     'Operativni sistem: Android 9.0 Pie\n' +
    //     'Boja: Crna\n' +
    //     'TABPROC Quad-core 1.3 GHz\n' +
    //     'Memorija: RAM 1GB\n' +
    //     'Memorija: FLASH 16Gb\n' +
    //     'Priključci: microUSB 2.0\n' +
    //     'Bežično povezivanje: Wi-Fi 802.11 b/g/n, Wi-Fi Direct, hotspot\n' +
    //     'Čitač kartica: microSDXC (dedicated slot)\n' +
    //     'Kamera naprijed i nazad: Da, glavna 2MP (video 720p), Selfie 2MP (video 720MP)\n' +
    //     'Zvučnici: Da, 3,5mm jack\n' +
    //     'Senzori: Accelerometer\n' +
    //     'Baterija i napajanje: Neodvojiva 3590 mAh\n' +
    //     'Dodatne informacije: Zvuk: Dolby Atmos\n' +
    //     'Garancija (mj): 24',
    //     slika: TabletLenovoTabM7
    // },
    // {
    //     id: 13,
    //     naziv: 'Električni romobil UrbanGlide Ride 62S',
    //     cijena: 585,
    //     opis: 'Maksimalna brzina: do 25 km/h\n' +
    //     'Domet: do 15 km\n' +
    //     'Veličina: 17 cm (6,5")\n' +
    //     'Najveća nosivost: do 100 kg\n' +
    //     'Sklopljiv: Da\n' +
    //     'Osnovne informacije: Snaga motora je 250W, litij-ionska baterija,punjenje baterije je cca 3 sata\n' +
    //     'Dodatne informacije: crna boja, prednje led svjetlo\n' +
    //     'Težina: 7,3 kg\n' +
    //     'Garancija (mj): 12 mjeseci',
    //     slika: ElektricniRomobil
    // },
    // {
    //     id: 14,
    //     naziv: 'Laptop Asus X403JA-WB511R',
    //     cijena: 1887,
    //     opis: 'Displej: 35,5 cm (14,0\'\') //Narrow border//300nits//FHD (1920 x 1080) 16:9//Anti-glare display//sRGB: 100%//IPS-level\n' +
    //     'Procesor: Intel Core i5 -1035G1 Processor 1.0 GHz (6M Cache, up to 3.6 GHz)\n' +
    //     'Operativni sistem: Windows 10 Pro\n' +
    //     'Memorija: (RAM) 8 GB LPDDR4 on board\n' +
    //     'SSD disk: 512 GB M.2 NVMe PCIe SSD with 32GB Intel Optane Memory\n' +
    //     'Grafička kartica: Integrirana\n' +
    //     'Vrsta prenosnog računara: Multimedija\n' +
    //     'Touchscreen: NE\n' +
    //     'Docking stanica: NE\n' +
    //     'Boja: Srebrna, Silver Blue & Sand Blast\n' +
    //     'Priključci: 1x USB 2.0 Type-A, 1x USB 3.2 Gen 1 Type-A, 1x Thunderbolt 3, 1x 3.5mm Combo Audio Jack, 1x HDMI 1.4\n' +
    //     'Čitač kartica: Type: Standard SD\n' +
    //     'Miš i tastatura: Backlit Chiclet tastatura\n' +
    //     'Zvučnici i kamera: Built-in array mikrofon, Sonic Master, ugrađen zvučnik, ICEpower\n' +
    //     'Bežično povezivanje: Wi-Fi 6(Gig+)(802.11ax)+Bluetooth 5.0 (Dual band) 2*2\n' +
    //     'Optička jedinica: ne\n' +
    //     'Baterija i napajanje: 72WHrs, 4S1P, 4-cell Li-ion, 45W AC Adapter\n' +
    //     'Dimenzije (Š x D x V): 32.3(W) x 20.8(D) x 1.65 ~ 1.65 (H) cm\n' +
    //     'Težina: 1.35 KG (with 4 cell battery)\n' +
    //     'Garancija (mj): 24 mjeseca',
    //     slika: LaptopAsusX403JA
    // },
    // {
    //     id: 15,
    //     naziv: 'Auto kamera HAMA "60" Ultra Wide-Angle Lens, Automatic Night Vision',
    //     cijena: 94,
    //     opis: 'Photo resolution: 12MP\n' +
    //     'Video resolution: 1080p\n' +
    //     'Dimenzije (Š x D x V): 3x5,8x4,7cm',
    //     slika: AutoKamera
    // },
    
];

export const potrosniMaterijal = [
    {
        id: 1,
        naziv: 'Drum, AltaLink B8045 / B8055 / B8065 / B8075 / B8090 (013R00675)',
        cijena: 400,
        opis: "P/N artikla: 013R00675\n" +
        'Kapacitet: 200.000 stranica\n' +
        'Boja: Black\n' +
        'Kompatibilni proizvodi: AltaLink B8045 / B8055 / B8065 / B8075 / B8090',
        slika: PM013R00675
    },
    {
        id: 2,
        naziv: 'Drum, AltaLink C8000 Series, WorkCentre 7500, 7800, 7800i Series, 7970/7970i, EC7836/EC7856 (013R00662)',
        cijena: 400,
        opis: "P/N artikla: 013R00662\n" +
        'Kapacitet: 125.000 stranica\n' +
        'Boja: Black/Color\n' +
        'Kompatibilni proizvodi: AltaLink C8030, C8035, C8045, C8055, C8070; WorkCentre 7525, 7530, 7535, 7545, 7556, 7830, 7835, 7845, 7855, 7830i, 7835i, 7845i, 7855i, 7970, 7970i, EC7836, EC7856',
        slika: PM013R00662
    },
    {
        id: 3,
        naziv: 'Drum, DocuColor 240/242/250/252/260 WorkCentre 7655/7665/7675/7755/7765/7775 (013R00602) - Black',
        cijena: 400, 
        opis: 'P/N artikla: 013R00603\n' +
        'Kapacitet: 80.000 stranica\n' +
        'Boja: Black\n' +
        'Kompatibilni proizvodi: DocuColor 240/242/250/252/260 WorkCentre 7655/7665/7675/7755/7765/7775',
        slika: PM013R00602
    },
    {
        id: 4,
        naziv: 'Drum, DocuColor 240/242/250/252/260 WorkCentre 7655/7665/7675/7755/7765/7775 (013R00603) - Color',
        cijena: 400,
        opis: 'P/N artikla: 013R00603\n' +
        'Kapacitet: 100.000 stranica\n' +
        'Boja: Color\n' +
        'Kompatibilni proizvodi: DocuColor 240/242/250/252/260 WorkCentre 7655/7665/7675/7755/7765/7775',
        slika: PM013R00603
    },
    {
        id: 5,
        naziv: 'Drum, Primelink B9100, B9110, B9125, B9136 (013R00684)',
        cijena: 400,
        opis: 'P/N artikla: 013R00684\n' +
        'Kapacitet: 800.000 stranica\n' +
        'Boja: Black\n' +
        'Kompatibilni proizvodi: Primelink B9100, B9110, B9125, B9136',
        slika: PM013R00684
    },
    {
        id: 6,
        naziv: 'Drum, PrimeLink C9065, C9070, Color C60, Color C70, Color 570, Color 560, Color 550 (013R00663) – BLACK',
        cijena: 400,
        opis: 'P/N artikla: 013R00663\n' +
        'Kapacitet: 190.000 stranica\n' +
        'Boja: Crna\n' +
        'Kompatibilni proizvodi: PrimeLink C9065, C9070, Color C60, Color C70, Color 570, Color 560, Color 550',
        slika: PM013R00663
    },
    {
        id: 7,
        naziv: 'Drum, PrimeLink C9065, C9070, Color C60, Color C70, Color 570, Color 560, Color 550 (013R00664) – COLOR',
        cijena: 400,
        opis: 'P/N artikla: 013R00664\n' +
        'Kapacitet: 85.000 stranica\n' +
        'Boja: Color\n' +
        'Kompatibilni proizvodi: PrimeLink C9065, C9070, Color C60, Color C70, Color 570, Color 560, Color 550',
        slika: PM013R00664
    },
    {
        id: 8,
        naziv: 'Drum, VersaLink B7025/B7030/B7035 (113R00779)',
        cijena: 400,
        opis: 'P/N artikla: 113R00779\n' +
        'Kapacitet: 80.000 stranica\n' +
        'Boja: Black\n' +
        'Kompatibilni proizvodi: VersaLink B7025/B7030/B7035',
        slika: PM113R00779
    },
    {
        id: 9,
        naziv: 'Drum, VersaLink C7020/C7025/C7030 (113R00780)',
        cijena: 400,
        opis: 'P/N artikla: 113R00780\n' +
        'Kapacitet: 87.000 stranica\n' +
        'Boja: Black/Color\n' +
        'Kompatibilni proizvodi: VersaLink C7020/C7025/C7030',
        slika: PM113R00780
    },
    {
        id: 10,
        naziv: 'Drum, Versant 80 / 180 / 2100 / 3100 Press (013R00676)',
        cijena: 400,
        opis: 'P/N artikla: 013R00676\n' +
        'Kapacitet: 348.000 stranica\n' +
        'Boja: Magenta\n' +
        'Kompatibilni prpizvodi: Versant 80 / Versant 2100 / Versant 180 / Versant 3100 (Mijenja p/n 013R00674)',
        slika: PM013R00676
    },
    {
        id: 11,
        naziv: 'Toner AltaLink B8045/B8055/B8065/B8075/B8090, Black, Standard Capacity (006R01605)',
        cijena: 400,
        opis: 'P/N artikla: 006R01605\n' +
        'Kapacitet: 100.000 stranica\n' +
        'Boja: Black\n' +
        'Kompatibilni proizvodi: AltaLink B8045/B8055/B8065/B8075/B8090, WorkCentre 5945/5955/5945i/5955i',
        slika: PM006R01605
    },
    {
        id: 12,
        naziv: 'Toner PrimeLink® B9100/B9110/B9125/B9136, Black (006R01766)',
        cijena: 400,
        opis: 'P/N artikla: 006R01766\n' +
        'Kapacitet: 71.500 stranica\n' +
        'Boja: Black\n' +
        'Kompatibilni proizvodi: PrimeLink® B9100/B9110/B9125/B9136',
        slika: PM013R00684
    },
    {
        id: 13,
        naziv: 'Toner VersaLink B7025/B7030/B7035 Crni, High Capacity (106R03394)',
        cijena: 400,
        opis: 'P/N artikla: 106R03394\n' +
        'Kapacitet: 31.000 stranica\n' +
        'Boja: Black\n' +
        'Kompatibilni proizvodi: VersaLink B7025,B7030,B7035',
        slika: PM106R03394 
    },
    {
        id: 14,
        naziv: 'Toner VersaLink B7025/B7030/B7035 Crni, Standard Capacity (106R03393)',
        cijena: 400,
        opis: 'P/N artikla: 106R03393\n' +
        'Kapacitet: 15.500 stranica\n' +
        'Boja: Black\n' +
        'Kompatibilni proizvodi: VersaLink B7025,B7030,B7035',
        slika: PM106R03394
    },
    {
        id: 15,
        naziv: 'Set Tonera AltaLink C8030/C8035/C8045/C8055/C8070 Standard Capacity',
        cijena: 400,
        opis: 'Toner: AltaLink C8030/C8035/C8045/C8055/C8070 Standard Capacity, Black\n' +
        'P/N artikla: 006R01697\n' +
        'Kapacitet: 26.000 stranica\n\n' +
        'Toner: AltaLink C8030/C8035/C8045/C8055/C8070 Standard Capacity, Cyan\n' +
        'P/N artikla: 006R01698\n' +
        'Kapacitet: 15.000 stranica\n\n' +
        'Toner: AltaLink C8030/C8035/C8045/C8055/C8070 Standard Capacity, Magenta\n' +
        'P/N artikla: 006R01699\n' +
        'Kapacitet: 15.000 stranica\n\n' +
        'Toner: AltaLink C8030/C8035/C8045/C8055/C8070 Standard Capacity, Yellow\n' +
        'P/N artikla: 006R01700\n' +
        'Kapacitet: 15.000 stranica',
        slika: PMSetTonera
    },
    {
        id: 16,
        naziv: 'Set Tonera VersaLink C7020/C7025/C7030 High Capacity',
        cijena: 400,
        opis: 'Toner: VersaLink C7020/C7025/C7030 High Capacity, Black\n' +
        'P/N artikla: 106R03741\n' +
        'Kapacitet: 16.100 stranica\n\n' +
        'Toner: VersaLink C7020/C7025/C7030 High Capacity, Yellow\n' +
        'P/N artikla: 106R03742\n' +
        'Kapacitet: 9.800 stranica\n\n' +
        'Toner: VersaLink C7020/C7025/C7030 High Capacity, Magenta\n' +
        'P/N artikla: 106R03743\n' +
        'Kapacitet: 9.800 stranica\n\n' +
        'Toner: VersaLink C7020/C7025/C7030 High Capacity, Cyan\n' +
        'P/N artikla: 106R03744\n' +
        'Kapacitet: 9.800 stranica',
        slika: PMSetTonera2
    },
    {
        id: 17,
        naziv: 'Toneri PrimeLink® C9065/C9070, Standard Capacity',
        cijena: 400,
        opis: 'Toner: PrimeLink® C9065/C9070 Standard Capacity, Black\n' +
        'P/N artikla: 006R01734\n' +
        'Kapacitet: 30.000 stranica\n\n' +
        'Toner: PrimeLink® C9065/C9070 Standard Capacity, Cyan\n' +
        'P/N artikla: 006R01735\n' +
        'Kapacitet: 34.000 stranica\n\n' +
        'Toner: PrimeLink® C9065/C9070 Standard Capacity, Magenta\n' +
        'P/N artikla: 006R01736\n' +
        'Kapacitet: 32.000 stranica\n\n' +
        'Toner: PrimeLink® C9065/C9070 Standard Capacity, Yellow\n' +
        'P/N artikla: 006R01737\n' +
        'Kapacitet: 34.000 stranica',
        slika: PMSetTonera3
    },
    {
        id: 18,
        naziv: 'Toneri Versant 80/180, Standard Capacity',
        cijena: 400,
        opis: 'Toner: Versant 80/180 Standard Capacity, Black\n' +
        'P/N artikla: 006R01642\n' +
        'Kapacitet: 20.000 stranica\n\n' +
        'Toner: Versant 80/180 Standard Capacity, Cyan\n' +
        'P/N artikla: 006R01643\n' +
        'Kapacitet: 22.000 stranica\n\n' +
        'Toner: Versant 80/180 Standard Capacity, Magenta\n' +
        'P/N artikla: 006R01644\n' +
        'Kapacitet: 21.000 stranic\n\n' +
        'Toner: Versant 80/180 Standard Capacity, Yellow\n' +
        'P/N artikla: 006R01645\n' +
        'Kapacitet: 22.000 stranica',
        slika: PMSetTonera4
    },
    {
        id: 19,
        naziv: 'Toneri, DocuColor 240/242/250/252/260 WorkCentre 7655/7665/7675/7755/7765/7775',
        cijena: 400,
        opis: 'P/N artikla: 006R01219\n' +
        'Kapacitet: 30.000 stranica\n' +
        'Boja: Black\n\n' +
        'P/N artikla: 006R01220\n' +
        'Kapacitet: 34.000 stranica\n' +
        'Boja: Yellow\n\n' +
        'P/N artikla: 006R01221\n' +
        'Kapacitet: 34.000 stranica\n' +
        'Boja: Magenta\n\n' +
        'P/N artikla: 006R01222\n' +
        'Kapacitet: 34.000 stranica\n' +
        'Boja: Cyan',
        slika: PMSetTonera5
    },
    {
        id: 20,
        naziv: 'Toneri, WorkCentre 7525/7530/7535/7545/7556/7830/7835/7840/7855',
        cijena: 400,
        opis: 'P/N artikla: 006R01517\n' +
        'Kapacitet: 26.000 stranica\n' +
        'Boja: Black\n\n' +
        'P/N artikla: 006R01518\n' +
        'Kapacitet: 15.000 stranica\n' +
        'Boja: Yellow\n\n' +
        'P/N artikla: 006R01519\n' +
        'Kapacitet: 15.000 stranica\n' +
        'Boja: Magenta\n\n' +
        'P/N artikla: 006R01520\n' +
        'Kapacitet: 15.000 stranica\n' +
        'Boja: Cyan',
        slika: PMSetTonera6
    }
];

export const mreznaOprema = [
    {
        id: 1,
        naziv: 'Dell EMC Networking X1018P Smart Web Managed Switch',
        cijena: 400,
        opis: "Number of Ports: 16 x 10Base-T/100Base-TX/1000Base-T, 2 x Fibre Channel\n" +
        'MAC Address Table Size: 16K\n' +
        'Network/Transport Protocol: TCP/IP, ARP, TFTP, DHCP, BOOTP, Telnet, SNMP, IGMPv2\n' +
        'Mounting Capability: Rackmount\n' +
        'Standard Rack Height: 1U\n' +
        'SFP Slot Quantity: 2\n' +
        'LAN: 16 (RJ-45)\n' +
        'Dimension (d x h x w): 250 x 41.25 x 209 mm\n' +
        'Nominal weight: 1.76 kg\n' +
        'Maximum Operating Ambient Temperature: 45 °C\n' +
        'Maximum Operating Humidity: 90%\n' +
        'Minimum Operating Ambient Temperature: 0 °C\n' +
        'Minimum Operating Humidity: 10 °C\n' +
        'Warranty Products returnable: Yes\n' +
        'Warranty validation Criteria: Serial Number\n' +
        'Pack Weight Brutto (kg): 2 kg\n' +
        'Pack Weight Netto (kg): 2 kg\n' +
        'Pack Weight (kg): 2 kg\n' +
        'Pieces in pack: 1\n' +
        'Box Weight Brutto (kg): 2 kg\n' +
        'Retail Packaging Net Weight Carton: 2 kg\n' +
        'Retail Packaging Net Weight Plastic: 2 kg\n' +
        'Packs in Box: 1',
        slika: MO1
    },
    {
        id: 2,
        naziv: 'DELL EMC PowerEdge T40, BTX',
        cijena: 400,
        opis: "System Type: Server\n" +
        'System Form Factor: Mini Tower\n' +
        'CPU: Intel Xeon E-2224G Processor\n' +
        'Internal Clock Rate: 3.5 GHz (up to 4.7 GHz)\n' +
        'CPU Core Quantity: 4\n' +
        'Threads Quantity: 4\n' +
        'CPU Socket Standard: Socket 1151\n' +
        'CPU Installed Quantity: 1\n' +
        'CPU Maximum Quantity Supports: 1\n' +
        'CPU Cache Size: 8 MB\n' +
        'Chipset: Intel C246\n' +
        'Features: Supports 14 nm Processors \n' +
        'Quantity of Mainboards Supported: 1\n' +
        'Memory Type: DDR4 SDRAM\n' +
        'Memory Form Factor: DIMM 288-pin\n' +
        'Memory Speed: 2666MHz(PC4-21300)\n' +
        'Provides Error Checking Method: ECC\n' +
        'Installed System Memory Storage Capacity: 8 GB\n' +
        'Maximum Installable System Memory Storage Capacity: 64 GB\n' +
        'Memory Slot Total Quantity: 4\n' +
        'Memory Features: Unbuffered \n' +
        'Hard Disk Drive: 1 TB, 7200 rpm, SATA III-600, 3.5&quot; Form Factor\n' +
        'Disk Drive Type: DVD±RW\n' +
        'Device Location: Internal\n' +
        'Video Controller Form Factor: Integrated\n' +
        'Video Chipset: Intel UHD Graphics P630\n' +
        'Video Memory Type: Shared Video Memory\n' +
        'Networking Form Factor: Integrated\n' +
        'LAN Chipset: Intel i219\n' +
        'LAN: Gigabit Ethernet\n' +
        'Data Link Protocol Supports: Gigabit Ethernet/Fast Ethernet/Ethernet\n' +
        'Number of 3.5-inch Internal Drive Bays: 3\n' +
        'Number of Slim External Drive Bays: 1\n' +
        'PCI Express 3.0 x16: 1\n' +
        'PCI Express 3.0 x4: 2\n' +
        'PCI: 1\n' +
        'LAN: 1 (RJ-45)\n' +
        'USB 2.0: 4 (USB 2.0 (type A))\n' +
        'USB 3.0: 5 (USB 3.0 (type A))\n' +
        'USB 3.1: 1 (USB Type-C)\n' +
        'Serial: 1 (9-pin)\n' +
        'DisplayPort: 2\n' +
        'PS/2: 2\n' +
        'Audio Line-In: 1 (3.5-mm minijack)\n' +
        'Headphones: 1 (3.5-mm minijack)\n' +
        'Power Device Type: Power Supply\n' +
        'Power Device Location: Internal\n' +
        'Input Voltage: AC 100-240 V\n' +
        'Input Frequency: 50/60 Hz\n' +
        'Power Device Installed Quantity: 1\n' +
        'Power Device Maximum Quantity Supports: 1\n' +
        'Maximum Output Power: 300 W\n' +
        'Operating Current: 6 A\n' +
        'Maximum Operating Ambient Temperature: 35 °C\n' +
        'Maximum Operating Humidity: 80%\n' +
        'Minimum Operating Ambient Temperature: 10 °C\n' +
        'Minimum Operating Humidity: 20%\n' +
        'Max. Altitude Operating: 3048 m\n' +
        'Dimension (h x d x w): 335 x 359.5 x 176.53 mm\n' +
        'Nominal Weight: 8 kg\n' +
        'Warranty Products Returnable: Yes\n' +
        'Warranty validation Criteria: Serial Number\n' +
        'Warranty Term Notes: Next Business Day\n' +
        'Pack Weight Brutto (kg): 15 kg\n' +
        'Pack Weight Netto (kg): 8.4 kg\n' +
        'Pieces in pack: 1\n' +
        'Box Weight Brutto (kg): 15 kg\n' +
        'Retail Packaging Net Weight Carton: 6.6 kg\n' +
        'Retail Packaging Net Weight Plastic: 0 kg\n' +
        'Packs in Box: 1\n' +
        'Package Type: Box',
        slika: MO2
    },
    {
        id: 3,
        naziv: 'DELL EMC PowerEdge T140',
        cijena: 400,
        opis: "System Type: Server\n" +
        'System Form Factor: Tower\n' +
        'CPU: Intel Xeon E-2224 Processor\n' +
        'Internal Clock Rate: 3.4 GHz (up to 4.6 GHz)\n' +
        'CPU Core Quantity: 4\n' +
        'Threads Quantity: 4\n' +
        'CPU Socket Standard: Socket 1151\n' +
        'CPU Installed Quantity: 1\n' +
        'CPU Maximum Quantity Supports: 1\n' +
        'CPU Cache Size: 8 MB\n' +
        'Features: Supports 14 nm Processors \n' +
        'Quantity of Mainboards Supported: 1\n' +
        'Memory Type: DDR4 SDRAM\n' +
        'Memory Form Factor: DIMM 288-pin\n' +
        'Memory Speed: 2666MHz(PC4-21300)\n' +
        'Provides Error Checking Method: ECC\n' +
        'Installed System Memory Storage Capacity: 16 GB\n' +
        'Maximum Installable System Memory Storage Capacity: 64 GB\n' +
        'Memory Slot Total Quantity: 4\n' +
        'Memory Features: Unbuffered \n' +
        'Hard Disk Drive: 1 TB\n' +
        'Disk Drive Type: DVD±RW\n' +
        'Device Location: Internal\n' +
        'Optical Drive Interface Type: SATA\n' +
        'Networking Form Factor: Integrated\n' +
        'LAN: Gigabit Ethernet\n' +
        'Data Link Protocol Supports: Gigabit Ethernet/Fast Ethernet/Ethernet\n' +
        'Number of 3.5-inch Internal Drive Bays: 4\n' +
        'Number of Slim External Drive Bays: 1\n' +
        'PCI Express 3.0 x16: 1\n' +
        'PCI Express 3.0 x8: 2\n' +
        'PCI Express 3.0 x1: 1\n' +
        'USB 2.0: 4 (USB 2.0 (type A))\n' +
        'USB 3.0: 3 (USB 3.0 (type A))\n' +
        'LAN: 2 (RJ-45)\n' +
        'Serial: 1 (9-pin DB)\n' +
        'VGA: 1 (15-pin D-sub)\n' +
        'Power Device Type: Power Supply\n' +
        'Power Device Location: Internal\n' +
        'Input Voltage: AC 100-240 V\n' +
        'Input Frequency: 50/60 Hz\n' +
        'Power Device Installed Quantity: 1\n' +
        'Power Device Maximum Quantity Supports: 1\n' +
        'Maximum Output Power: 365 W\n' +
        'Maximum Operating Ambient Temperature: 35 °C\n' +
        'Maximum Operating Humidity: 80%\n' +
        'Minimum Operating Ambient Temperature: 10 °C\n' +
        'Minimum Operating Humidity: 10%\n' +
        'Max. Altitude Operating: 3048 m\n' +
        'Dimension (h x d x w): 360 x 454 x 175 mm\n' +
        'Nominal Weight: 11.8 kg\n' +
        'Warranty Products Returnable: Yes\n' +
        'Warranty validation Criteria: Serial Number\n' +
        'Pack Weight Brutto (kg): 10 kg\n' +
        'Pack Weight Netto (kg): 10 kg\n' +
        'Pieces in pack: 1\n' +
        'Box Weight Brutto (kg): 10 kg\n' +
        'Retail Packaging Net Weight Carton: 10 kg\n' +
        'Retail Packaging Net Weight Plastic: 10 kg\n' +
        'Packs in Box: 1\n' +
        'Packs per Pallet: 12\n' +
        'Package Type: Box',
        slika: MO3
    },
    {
        id: 4,
        naziv: 'Dell Networking X1026P Smart Web Managed Switch',
        cijena: 400,
        opis: "Number of Ports: 26 24 x 10Base-T/100Base-TX/1000Base-T, 2 x Fibre Channel\n" +
        'Switching Protocol: Ethernet\n' +
        'MAC Address Table Size: 16K\n' +
        'Network/Transport Protocol: IPv4, TCP/IP, UDP, IPv6, ARP, TFTP, DHCP, SNMPv3, BOOTP, Telnet, SNMP, IGMPv2, SSHv2, RMON, SNMPv2, LLDP\n' +
        'Data Transmission: Full Duplex\n' +
        'Mounting Capability: Rackmount\n' +
        'Standard Rack Height: 1U\n' +
        'Fans Quantity: 2\n' +
        'LAN: 24 (RJ-45)\n' +
        'SFP Slot Quantity: 2\n' +
        'Power Supply: Internal\n' +
        'Power Consumption Operational: 450 W\n' +
        'Dimension (d x h x w): 250 x 41.25 x 209 mm\n' +
        'Nominal weight: 3.8 kg\n' +
        'Maximum Operating Ambient Temperature: 50 °C\n' +
        'Maximum Operating Humidity: 90%\n' +
        'Minimum Operating Ambient Temperature: 0 °C\n' +
        'Minimum Operating Humidity: 10 °C\n' +
        'Warranty Products returnable: Yes\n' +
        'Warranty validation Criteria: Serial Number\n' +
        'Pack Weight Brutto (kg): 1 kg\n' +
        'Pack Weight Netto (kg): 1 kg\n' +
        'Pieces in pack: 1\n' +
        'Box Weight Brutto (kg): 1 kg\n' +
        'Retail Packaging Net Weight Carton: 1 kg\n' +
        'Retail Packaging Net Weight Plastic: 1 kg\n' +
        'Packs in Box: 1',
        slika: MO4
    },
    {
        id: 5,
        naziv: 'FortiAnalyzer AP832E, FORTINET',
        cijena: 400,
        opis: "Interface: Ethernet/Wi-Fi\n" +
        'Local Area Network Architecture: 10Base-T/100Base-TX/1000Base-T\n' +
        'Networking Form Factor: External\n' +
        'Uplink Port Type: 1 x 10Base-T/100Base-TX/1000Base-T\n' +
        'Total Number of Ports: 2\n' +
        'Switching Protocol: Ethernet\n' +
        'Data Link Protocol Supports: Gigabit Ethernet/Fast Ethernet/Ethernet, IEEE 802.11i, IEEE 802.1x, IEEE 802.11a/b/g/n/ac\n' +
        'Device Location: External\n' +
        'Wi-Fi Band Frequency: 2.4 GHz/5 GHz\n' +
        'Status Indicator: Activity Status',
        slika: MO5
    },
    {
        id: 6,
        naziv: 'FortiAnalyzer AP832E, FORTINET',
        cijena: 400,
        opis: "Interface: Ethernet/Wi-Fi\n" +
        'Local Area Network Architecture: 10Base-T/100Base-TX/1000Base-T, Wi-Fi\n' +
        'Networking Form Factor: External\n' +
        'Uplink Port Type: 2 x 10Base-T/100Base-TX/1000Base-T\n' +
        'Total Number of Ports: 2\n' +
        'Data Link Protocol Supports: Gigabit Ethernet/Fast Ethernet/Ethernet, IEEE 802.11a/b/g/n/ac\n' +
        'Device Location: External\n' +
        'Network/Transport Protocol: MSCHAPv2, PEAP\n' +
        'Data Bit Rate: 1733 Mbps\n' +
        'Wi-Fi Band Frequency: 2.4 GHz/5 GHz',
        slika: MO6
    },
    {
        id: 7,
        naziv: 'FortiAnalyzer AP832E, FORTINET',
        cijena: 400,
        opis: "Interface: Ethernet/Wi-Fi\n" +
        'Local Area Network Architecture: Gigabit Ethernet, Wi-Fi\n' +
        'Networking Form Factor: External\n' +
        'Total Number of Ports: 1\n' +
        'Data Link Protocol Supports: IEEE 802.11a/b/g/n/ac\n' +
        'Device Location: External\n' +
        'Network/Transport Protocol: MSCHAPv2, PEAP\n' +
        'Data Bit Rate: 867 Mbps\n' +
        'Wi-Fi Band Frequency: 2.4 GHz/5 GHz',
        slika: MO7
    },
    {
        id: 8,
        naziv: 'PowerEdge R240, DELL EMC',
        cijena: 400,
        opis: "System Type: Server\n" +
        'System Form Factor: Rack-Mountable\n' +
        'Included Accessories: Rails, Rails\n' +
        'CPU: Intel Xeon E-2224 Processor\n' +
        'Internal Clock Rate: 3.5 GHz (up to 4.6 GHz)\n' +
        'CPU Core Quantity: 4\n' +
        'Threads Quantity: 4\n' +
        'CPU Socket Standard: Socket 1151\n' +
        'CPU Installed Quantity: 1\n' +
        'CPU Maximum Quantity Supports: 1\n' +
        'CPU Cache Size: 8 MB\n' +
        'Features: Supports 14 nm Processors \n' +
        'Quantity of Mainboards Supported: 1\n' +
        'Memory Type: DDR4 SDRAM\n' +
        'Memory Form Factor: DIMM 288-pin\n' +
        'Memory Speed: 2666MHz(PC4-21300)\n' +
        'Provides Error Checking Method: ECC\n' +
        'Installed System Memory Storage Capacity: 16 GB\n' +
        'Maximum Installable System Memory Storage Capacity: 64 GB\n' +
        'Memory Slot Total Quantity: 4\n' +
        'Memory Features: Unbuffered \n' +
        'Hard Disk Drive: 1 TB, SATA\n' +
        'Disk Drive Type: DVD±RW\n' +
        'Device Location: Internal\n' +
        'Networking Form Factor: Integrated\n' +
        'LAN: Gigabit Ethernet\n' +
        'Data Link Protocol Supports: Gigabit Ethernet/Fast Ethernet/Ethernet\n' +
        'Number of 3.5-inch Internal Drive Bays: 4\n' +
        'PCI Express 3.0 x8: 1\n' +
        'PCI Express 3.0 x16: 1\n' +
        'LAN: 2 (RJ-45)\n' +
        'USB 2.0: 1 (USB 2.0 (type A))\n' +
        'USB 3.0: 2 (USB 3.0 (type A))\n' +
        'Serial: 1 (9-pin)\n' +
        'VGA: 1 (15-pin D-sub)\n' +
        'Power Device Type: Power Supply\n' +
        'Power Device Location: Internal\n' +
        'Input Voltage: AC 100-240 V\n' +
        'Input Frequency: 50/60 Hz\n' +
        'Power Device Installed Quantity: 1\n' +
        'Power Device Maximum Quantity Supports: 1\n' +
        'Maximum Output Power: 450 W\n' +
        'Maximum Operating Ambient Temperature: 35 °C\n' +
        'Maximum Operating Humidity: 80%\n' +
        'Minimum Operating Ambient Temperature: 10 °C\n' +
        'Minimum Operating Humidity: 10%\n' +
        'Max. Altitude Operating: 3048 m\n' +
        'Dimension (h x d x w): 42.8 x 595.6 x 434 mm\n' +
        'Warranty Products Returnable: Yes\n' +
        'Warranty validation Criteria: Serial Number\n' +
        'Pack Weight Brutto (kg): 10 kg\n' +
        'Pack Weight Netto (kg): 10 kg\n' +
        'Pieces in pack: 1\n' +
        'Box Weight Brutto (kg): 10 kg\n' +
        'Retail Packaging Net Weight Carton: 10 kg\n' +
        'Retail Packaging Net Weight Plastic: 10 kg\n' +
        'Packs in Box: 1\n' +
        'Packs per Pallet: 12\n' +
        'Package Type: Box',
        slika: MO8
    },
    {
        id: 9,
        naziv: 'PowerEdge R540, DELL EMC',
        cijena: 400,
        opis: "System Type: Server\n" +
        'System Form Factor: Rack-Mountable\n' +
        'Included Accessories: Rails, Rails\n' +
        'CPU: Intel Xeon Silver 4210 Processor\n' +
        'Internal Clock Rate: 2.2 GHz (up to 3.2 GHz)\n' +
        'CPU Core Quantity: 10\n' +
        'Threads Quantity: 20\n' +
        'CPU Socket Standard: Socket 3647\n' +
        'CPU Installed Quantity: 1\n' +
        'CPU Maximum Quantity Supports: 2\n' +
        'CPU Cache Size: 13.75 MB\n' +
        'Features: Supports 14 nm Processors \n' +
        'Quantity of Mainboards Supported: 1\n' +
        'Memory Type: DDR4 SDRAM\n' +
        'Memory Form Factor: DIMM 288-pin\n' +
        'Memory Speed: 2666MHz(PC4-21300)\n' +
        'Provides Error Checking Method: ECC\n' +
        'Installed System Memory Storage Capacity: 16 GB\n' +
        'Maximum Installable System Memory Storage Capacity: 1 TB\n' +
        'Memory Slot Total Quantity: 16\n' +
        'Memory Features: Registered \n' +
        'Solid State Drive: 480 GB\n' +
        'Networking Form Factor: Integrated\n' +
        'LAN: Gigabit Ethernet\n' +
        'Data Link Protocol Supports: Gigabit Ethernet/Fast Ethernet/Ethernet\n' +
        'Number of 3.5-inch Internal Drive Bays: 12\n' +
        'LAN: 2 (RJ-45)\n' +
        'USB 2.0: 2 (USB 2.0 (type A))\n' +
        'USB 3.0: 2 (USB 3.0 (type A))\n' +
        'Serial: 1 (9-pin)\n' +
        'VGA: 2 (15-pin D-sub)\n' +
        'Management Port: 1 (Micro USB)\n' +
        'Power Device Type: Power Supply - redundant\n' +
        'Power Device Location: Internal\n' +
        'Input Frequency: 50/60 Hz\n' +
        'Power Device Installed Quantity: 2; Redundant (1+1)\n' +
        'Power Device Maximum Quantity Supports: 2\n' +
        'Maximum Output Power: 750 W\n' +
        'Maximum Operating Ambient Temperature: 35 °C\n' +
        'Maximum Operating Humidity: 80%\n' +
        'Minimum Operating Ambient Temperature: 10 °C\n' +
        'Minimum Operating Humidity: 10%\n' +
        'Max. Altitude Operating: 3048 m\n' +
        'Dimension (h x d x w): 86.8 x 703.76 x 434 mm\n' +
        'Warranty Products Returnable: Yes\n' +
        'Warranty validation Criteria: Serial Number\n' +
        'Pack Weight Brutto (kg): 20 kg\n' +
        'Pack Weight Netto (kg): 20 kg\n' +
        'Pieces in pack: 1\n' +
        'Box Weight Brutto (kg): 20 kg\n' +
        'Retail Packaging Net Weight Carton: 20 kg\n' +
        'Retail Packaging Net Weight Plastic: 20 kg\n' +
        'Packs in Box: 1\n' +
        'Package Type: Box',
        slika: MO9
    },
    {
        id: 10,
        naziv: 'UBIQUITI EdgeRouter 4-Port',
        cijena: 400,
        opis: "Network Device Type: Router\n" +
        'Data Link Protocol Supports (New): LAN\n' +
        'CPU: MIPS 64\n' +
        'CPU Core Quantity: 4\n' +
        'Internal Clock Rate: 1 GHz\n' +
        'Installed Flash Memory Storage Capacity: 4 GB\n' +
        'External Device Support: USB Flash\n' +
        'Installed RAM: 1 GB',
        slika: MO10
    },
    {
        id: 11,
        naziv: 'UBIQUITI EdgeRouter X, 5-Port',
        cijena: 400,
        opis: "Network Device Type: Router\n" +
        'Data Link Protocol Supports (New): LAN\n' +
        'CPU: MIPS\n' +
        'CPU Core Quantity: 2\n' +
        'Internal Clock Rate: 880 MHz\n' +
        'Installed Buffer Memory Storage Capacity: 256 MB\n' +
        'Installed Flash Memory Storage Capacity: 256 MB',
        slika: MO11
    },
    {
        id: 12,
        naziv: 'UBIQUITI UniFi Security Gateway, PRO, 4-Port',
        cijena: 400,
        opis: "Network Device Type: Router\n" +
        'Data Link Protocol Supports (New): LAN\n' +
        'CPU: MIPS\n' +
        'CPU Core Quantity: 2\n' +
        'Internal Clock Rate: 1 GHz\n' +
        'Installed Flash Memory Storage Capacity: 4 GB\n' +
        'Installed RAM: 2 GB\n' +
        'External Connection Type: 2 x WAN (up to 1 Gbps)',
        slika: MO12
    },
    {
        id: 13,
        naziv: 'UBIQUITI UniFi Switch, 16-Port, 150W',
        cijena: 400,
        opis: "Number of Ports: 16 x 10Base-T/100Base-TX/1000Base-T, 2 x Fibre Channel\n" +
        'Switching Protocol: Ethernet\n' +
        'MAC Address Table Size: 16K\n' +
        'Networking Features: Manageable, Power over Ethernet (PoE), Power over Ethernet plus (PoE+)\n' +
        'Device Location: Plug-in Module\n' +
        'Mounting Capability: Rackmount\n' +
        'Standard Rack Height: 1U\n' +
        'Certifications: CE, FCC, IC',
        slika: MO13
    },
    {
        id: 14,
        naziv: 'UBIQUITI UniFi Switch, 16-Port, 150W',
        cijena: 400,
        opis: "Number of Ports: 48 x 10Base-T/100Base-TX/1000Base-T, 2 x Fibre Channel, 2 x Fibre Channel\n" +
        'Switching Protocol: Ethernet\n' +
        'MAC Address Table Size: 16K\n' +
        'Networking Features: Manageable\n' +
        'Device Location: Plug-in Module\n' +
        'Mounting Capability: Rackmount\n' +
        'Standard Rack Height: 1U\n' +
        'Certifications: CE, FCC, IC',
        slika: MO14
    },
]

export default {
    printeri, multifunkcionalniUredjaji, informatickaOprema, potrosniMaterijal, mreznaOprema
}