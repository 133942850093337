import React from 'react';
import Success from '../res/success.jpg';
import Software from '../res/software.jpg';
import Chess from '../res/chess.jpg';
import Printer from '../res/printer.jpg';
import Electric from '../res/electric.jpg';
import { List } from 'antd';
import { AiOutlineRight } from "react-icons/ai";

const list1 = [
    "Mi smo autorizovani Xerox prodavač i servis - Potreban Vam je profesionalni uređaj, želite iznimno kvalitetan ispis? Za sebe i svoje klijente želite samo najkvalitetnije? U potrazi ste za kvalitetnim i ekonomičnim otiskom?",
    "Mi smo autorizovani Dell prodavač i servis – Ne zaboravite da je sigurnost podataka ključ dobrog posla. Gdje čuvate podatke? Na koji način su pohranjeni? Konktaktirajte nas i uvjerite se da serverska rješenja mogu biti kvalitetna i povoljna ujedno.",
    "Softverska rješenja  - Razvili smo vlastiti program FACIT biblioteka, te isti implementirali u većinskom dijelu obrazovnih ustanova na teritoriji BIH. Razvili smo vlastita Document management rješenja za male i srednje organizacije.",
]

const list2 = [
    "Renta uređaja",
    "Da li ste često u potrazi za tonerom i servisom? Svaki put iznova trošite vrijeme u potrazi? Želite biti potpuno posvećeni svom poslu, zastoji sa print uređajem stvaraju Vam nepredviđene probleme? Želite savremeniji uređaj? Niste zadovoljni kvalitetom? Nov printer je za Vas nepotrebno velika investicija? Želite sve na jednom mjestu? IMAMO RJEŠENJE ZA VAS: ",
]

const list3 = [
    "Bez finansijskog opterećenja",
    "Nov i savremen uređaj",
    "Sigurna i kvalitetna podrška",
    "Brz odziv",
    "Certificirano osoblje",
    "Kvalitetan i ekonomičan ispis",
    "Kontaktirajte nas i već danas rentajte svoj uređaj!"
]

const list5 = [
    "Klasično održavanje",
    " Što je to klasično \u0022 Time & Material \u0022 održavanje?",
    "Klasično ili takozvano \u0022Time & Material\u0022 održavanje je najbolje rješenje za održavanja i podršku uređajima nižeg ranga. Ovakav model održavanja uređaja se zasniva na pozivu korisnika (npr.kod zastoja ili kvara uređaja). Uređaje servisiramo prema propisanim standardima proizvođača. Servisnu intervenciju plaća korisnik po važećem cjenovniku, efektivno utrošeno radno vrijeme, zamjenu materijala (rezervni dijelovi i potrošni materijal) te putne troškove. Kod ovakvog načina održavanja koristimo isključivo originalne rezervne dijelove te potrošni materijal."
]

const list6 = [
    "Da li ste razmišljali o e - protokolu ?",
    "Želite smanjiti troškove i probleme vezane za papir ?",
    "Želite smanjiti vrijeme izvršavanja procesa i povećati saradnju ?",
    "Želite smanjiti IT troškove ?",
    "Želite smanjiti troškove poštarine i kurirskih službi ?",
    "Želite smanjiti troškove potrebne za pravnu i regulatornu usklađenost ?",
    "Povećajte kontrolu i efikasnost svog preduzeća. Učinite poslovne procese transparentnijim. Kontaktirajte nas i saznajte kako već danas možete imati savremenu poslovnu organizaciju.",
]

const NasaPrica = () => {

    return (
        <div>
            <div id="container">
                <div id="mainText">
                    <div className="part">
                        <div className="tekst">
                            <div className="right">
                                <hr></hr>
                                <p style={{ marginTop: "3%" }}>
                                    {/* Kao potreba za pružanjem što kvalitetnije usluge na polju informacijskih tehnologija, 2009. godine pokrenuli smo kompaniju <b>FACIT d.o.o. </b> Snagom volje i željom za uspjehom od male firme uspjeli smo pozicionirati na tržištu bitan integrator inovativnih tehnologija od komercijalnog sektora, preko servisa i tehničke podrške, do specijaliziranih uslužnih djelatnosti printanja i knjižare. Zahvaljujući ljudskom faktoru koji nam pruža stabilnost, sigurnost i snagu a svaki novi uspjeh dodatnu kreativnost i dinamičnost, danas smo postali prepoznatljivi po kvalitetu servisiranja naših kupaca, njihovih zahtjeva i potreba. */}
                            FACIT je multibrand IT kompanija, koja se prateći trendove na tržištu profilisala kao lider kvaliteta, stručnosti i profesionalnosti. Osnovani smo 2009. godine, te smo do današnjeg dana pažljivo i vrijedno ulagali u naše osobolje, te proširili naš portifolij u više IT pravaca. Odlučili smo biti istrajni u njegovanju tradiocionalnih vrijednosti, ne zaboravljajući da je mladalački entuzijazam pokretač najvećih promjena.
                            </p>
                                <p> Porodična smo, višegeneracijska kompanija. Cijenimo individualizam, djelujemo timski. U našem portofoliju pronađite kompletno rješenje za Vaše poslovno okruženje.
                            </p>
                                <p style={{ marginTop: "5%" }}>
                                    <AiOutlineRight /> Mi smo autorizovani Epson prodavač i servis – Da li ste razmišljali o štetnim supstancama u Vašem okruženju? Da li znate da je INK JET budućnost printinga. Ukoliko već niste, onda je vrijeme da napokon udahnete svjež zrak uz inovativnu ink jet tehnologiju.
                            </p>
                            </div>
                        </div>
                        <img className="aboutImg" id="success" src={Success} alt="Success" />
                    </div>
                    <div className="part">
                        <div className="tekst">
                            <p className="leftCilj">
                                <hr></hr>
                                <List
                                    dataSource={list1}
                                    renderItem={item => (
                                        <List.Item>
                                            <AiOutlineRight /> {item}
                                        </List.Item>
                                    )}
                                />
                            </p>
                        </div>
                        <img className="aboutImg" id="printer" src={Printer} alt="Printer" />
                    </div>
                    <div className="part">
                        <div className="tekst">
                            <p className="right">
                                <hr></hr>
                                <List
                                    dataSource={list2}
                                    renderItem={item => (
                                        <List.Item>
                                            {item === list2[0] ? <AiOutlineRight /> : null} {item}
                                        </List.Item>
                                    )}
                                />
                                <List
                                    dataSource={list3}
                                    renderItem={item => (
                                        <List.Item style={{ margin: "0px", padding: "0px" }}>
                                            <div style={{ fontWeight: "bold" }}>
                                                {item !== list3[0] ? <AiOutlineRight /> : null}  {item}
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </p>
                        </div>
                        <img className="aboutImg" id="software" src={Software} alt="Software" />
                    </div>
                    <div className="part">
                        <div className="tekst">
                            <p className="left">
                                <hr></hr>
                                <List
                                    style={{ marginBottom: "0", padding: "0" }}
                                    dataSource={list5}
                                    size="small"
                                    renderItem={item => (
                                        <List.Item>
                                            {item === list5[0] ? <AiOutlineRight /> : null} {item}
                                        </List.Item>
                                    )}
                                /> </p>
                        </div>
                        <img className="aboutImg" id="chess" src={Chess} alt="Chess" />
                    </div>
                    <div className="part">
                        <div className="tekst">
                            <p className="right">
                                <hr></hr>
                                <List
                                    dataSource={list6}
                                    size="small"
                                    renderItem={item => (
                                        <List.Item>
                                            <div >
                                                {item !== list6[list6.length - 1] ? <AiOutlineRight /> : null}  {item}
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </p>
                        </div>
                        <img className="aboutImg" id="electric" src={Electric} alt="Electric" />
                    </div>
                </div>
            </div >
        </div>
    );
};

export default NasaPrica;